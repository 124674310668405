body {
  font-family: "Inter" !important;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  background: #F9FEFF;
  color: #000;
  overflow-x: hidden;
  user-select: none;
}
img {
  max-width: 100%;
  height: auto;
  user-select: none;
  -webkit-user-select: none;
}
h1 {
  font-size: 70px !important;
  line-height: 72px !important;
  color: #153a3a !important;
  font-family: "Inter" !important;
  font-weight: 700 !important;
  margin-bottom: 10px;
}
h2 {
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
  font-family: "Inter";
}
.as-link{
  cursor: pointer;
  text-decoration: none;
}
h4,
.modal-body h4 {
  font-family: "Inter";
  font-size: 17px !important;
  line-height: 20px !important;
  color: #000 !important;
  font-weight: 600 !important;
}
.custom-resume-btn {
  background: #ffd660 !important;
  height: 50px;
  min-width: 200px;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #000 !important;
  border-radius: 8px !important;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.custom-resume-btn:hover {
  background: #ffe28f !important;
}
.custm-selct-icon-btn {
  min-width: 78px !important;
  background-color: #0092A6;
  color: #fff;
  border-radius: 4px !important;
  border: none !important;
  height: 35px;
  font-size: 14px !important;
  line-height: 17px !important;
  font-weight: 700 !important;
  cursor: pointer;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.custm-selct-icon-btn:focus-visible {
  background-color: #0092A6 !important;
  color: #fff !important;
}
.custm-selct-icon-btn:active {
  background-color: #0092A6 !important;
  color: #fff !important;
}
.custm-selct-icon-btn:hover {
  background-color: #0092A6;
  color: #fff;
}
.gets-form-btn {
  background: #0092a6 !important;
  color: #fff !important;
  min-width: 194px !important;
  height: 56px !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  border: none !important;
  outline: none !important;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.gets-form-btn:hover {
  opacity: 0.8;
}
.search-input.form-control {
  min-width: 320px;
  height: 50px;
  background: #fff;
  border: none;
  outline: none;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #a4a4a4;
  border-radius: 8px;
  box-shadow: 0px 1.0029325485229492px 4.011730194091797px 0px #00000040;
  padding-left: 23px;
  padding-right: 23px;
  outline-offset: unset;
  padding-top: 0px;
  padding-bottom: 0px;
}
.search-input.form-control:focus {
  outline: none;
  border: none;
  box-shadow: 0px 1.0029325485229492px 4.011730194091797px 0px #00000040;
}
.search-input.form-control::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #a4a4a4;
}
.search-input.form-control::placeholder {
  color: #a4a4a4;
}
.custm-back-btn.btn.btn-primary {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  background: transparent !important;
  outline: none;
  border: none;
}
.custm-next-btn.btn.btn-primary {
  min-width: 200px;
  height: 50px;
  background: #ffd660 !important;
  box-shadow: 0px 1.0029325485229492px 4.011730194091797px 0px #00000040;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  outline: none;
  border: none;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custm-next-btn.btn.btn-primary:hover {
  background: #ffe28f !important;
}
/*For Vertical Scroll*/
.tabs-custom-listing-block {
  overflow-y: auto;
  height: 50vh;
  padding-right: 8px;
}
.tabs-custom-listing-block::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.tabs-custom-listing-block::-webkit-scrollbar-track {
  background: #45aad6;
  border-radius: 8px;
}

/* Handle */
.tabs-custom-listing-block::-webkit-scrollbar-thumb {
  background: #45aad6;
  border-radius: 8px;
}

/* Handle on hover */
.tabs-custom-listing-block::-webkit-scrollbar-thumb:hover {
  background: #038294;
}
.popover {
  background: #fff;
  border: none !important;
  border-radius: 5px !important;
  padding: 15px!important;
  max-width: 566px !important;
  min-width: 371px;
  /* position: absolute!important; */
  /* transform: translate(342px, -105px)!important; */
  /* inset: unset!important;
  left: 170px !important;
  top: 80% !important;
  margin-top: 147px; */
  /* transform: unset!important;
  inset: unset!important;
  top: 50%!important;
  margin-top: -50px; */
  /* right: auto !important; */
  left: 120px !important;
  z-index: 9999;
  /* pointer-events: none; */
  position: fixed!important;
  /* transform: translate3d(288px, -345px, 0px)!important; */
  -webkit-filter: drop-shadow(1px 1px 1px rgba(0,0,0,.5));
    filter: drop-shadow(1px 1px 1px rgba(0,0,0,.5));
  margin-bottom: 15px;
}
.popover .popover-arrow::after,
.popover .popover-arrow::before {
  width: 0px !important;
  height: 0px !important;
  border-style: solid !important;
  border-width: 20px 14px 0 14px !important;
  border-color: #ffffff transparent transparent transparent !important;
  transform: rotate(0deg) !important;
  top: -2px;
  left: auto;
  right: 116px;
}
.popover-body {
  padding: 0px !important;
  font-family: "Inter" !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.main-app-wrapper {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  height: 100vh;
  overflow-y: auto;
}
.MainCustomRow {
  flex-wrap: nowrap;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* css by dev */
.MainCustomSidebarr ul li {
  cursor: pointer;
}
