.faq-section {
    background: #E2F2ff;
    min-height: 100vh;
    padding: 24px;
    max-width: 100%;
}
.faq-title h2 {
  position: relative;
  margin-bottom: 45px;
  display: inline-block;
  font-weight: 600;
  line-height: 1;
}
.faq-title h2::before {
    content: "";
    position: absolute;
    left: 50%;
    width: 60px;
    height: 2px;
    background: #d5edef;
    bottom: -25px;
    margin-left: -30px;
}
.faq-title p {
  padding: 0 190px;
  margin-bottom: 10px;
}
.faq-section .container {
  max-width: 100%;
}

.faq {
  background: #fff;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  padding: 20px;
}

.faq .card {
  border: none;
  background: none;
  border-bottom: 1px dashed #CEE1F8;
}

.faq .card .card-header {
  padding: 0px;
  border: none;
  background: none;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.faq .card .card-header:hover {
    background-color: #E2F2ff;
    padding-left: 10px;
}
.faq .card .card-header .faq-title {
  width: 100%;
  text-align: left;
  padding: 0px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1px;
  color: #3B566E;
  text-decoration: none !important;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: Poppins;
  line-height: 20px;
}

.faq .card .card-header .faq-title .badge {
  display: inline-block;
  width: 23px;
  height: 23px;
  line-height: 14px;
  float: left;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  text-align: center;
  background: #0092A6;
  color: #fff;
  font-size: 12px;
  margin-right: 20px;
}

.faq .card .card-body {
  padding: 30px;
  padding-left: 35px;
  padding-bottom: 16px;
  font-weight: 400;
  font-size: 16px;
  color: #6F8BA4;
  line-height: 28px;
  letter-spacing: 1px;
  border-top: 1px solid #F3F8FF;
}

.faq .card .card-body p {
  margin-bottom: 14px;
  padding: 0px;
  font-size: 16px;
  font-family: Poppins;

}

textarea.form-control {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 16px;
    margin-top: 0px;
    font-family: Poppins;
    letter-spacing: 1px;
    line-height: 28px;
  }
  
  .btn {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
  }
  
  .btn-primary {
    background-color: #0092A6;
    color: #fff;
    border: none;
  }
  .faq .card .card-body button:active {
    background-color: #0092A6 !important;
  }
  .btn-secondary {
    background-color: #0092A6;
    color: #fff;
    border: none;
  }

  .collapse {
    overflow: hidden;
}
  
.collapse.show {
  max-height: 500px; /* Adjust as necessary */
}
  
  .slide-down {
    transition: height 0.5s ease-in-out;
}

.slide-up {
  transition: height 0.5s ease-in-out;
}
  .faq .card .card-body .btn.btn-secondary:hover {
    background-color: #0092A6 !important;
    color: #fff !important;
    border: none;
  }
  .faq .card .card-body div .btn.btn-primary:hover {
    background-color: #0092A6 !important;
    color: #fff !important;
    border: none;
  }
@media (max-width: 991px) {
  .faq {
    margin-bottom: 30px;
  }
  .faq .card .card-header .faq-title {
    line-height: 26px;
    margin-top: 10px;
  }
}
@media (max-width: 769px) {
    .col-md-10.offset-md-1 {
      padding: 0px 30px !important;
    }
    .faq-section .faq-tab {
      display: flex;
      flex-direction: column;
    }
    .faq-section .faq-tab .col-md-9.col-sm-9.story-according {
      width: 100%;
      padding-right: 12px !important;
    }
    .faq-section .faq-tab .col-md-3.col-sm-3.mb-4.story-content {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-direction: column;
    }
    .story-calander {
      display: flex;
      flex-direction: column;
  }
    .story-calander .as-link {
      width: 100%;
  }
  .story-calander .story-cards {
    width: 100%;
  }
  .story-calander .feature-card .feature-icon img {
    max-width: 64px;
  }
  .card-group-section .viewall-stroy {
    margin-bottom: 0px !important;
    width: 50%;
}
.card-group-section .viewall-stroy .card-view {
  width: 100%;
}
.card-group-section .dashboard-image {
  width: 50%;
}
}
@media (max-width: 480px) {
      .story-content {
          width: 100%;
      }
      .card-group-section .dashboard-image {
        width: 100%;
    }
    .card-group-section .viewall-stroy {
      width: 100%;
  }
}
