.Line_chart h4 {
  font-family: Inter !important;
  font-size: 20.05px !important;
  font-weight: 600 !important;
}
.Line_chart h4 {
  padding-top: 20px;
  padding-left: 6px;
}
.side-main-content .spinner-border.text-primary {
  color: #0092a6 !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  opacity: 0;
}
/* .side-main-content .loaderlayer {
  width: 100%;
} */
.side-main-content .loaderlayer .web-loader {
  position: absolute;
  top: 0px;/*12px*/
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 85%);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.setting-txt-placeholder.placeholder-container
  .custom-form-slect-field
  .dropdown
  button {
  text-align: start !important;
}
.setting-txt-placeholder.placeholder-container
  .custom-form-slect-field
  .dropdown-toggle::after {
  position: absolute;
  top: 19px;
  left: auto;
  right: 23px !important;
}
.main-app-wrapper .main-top-bar {
  background: #daecee;
}
.setting-listing-holder.scrolled
  .tabs-custom-listing-holder
  .setting-txt-placeholder.placeholder-container
  .show.dropdown
  .dropdown-menu.show {
  width: 195px;
}
.side-main-content
  .header-holder
  .custom-form-slect-field
  .dropdown.show
  .dropdown-menu.show {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 1px 4.01px 0px #00000040;
}
.custom-form-slect-field .show.dropdown .dropdown-menu.show {
  background-color: #ffffff;
  box-shadow: 0px 1px 4.01px 0px #00000040;
  border: none !important;
}
.custom-form-slect-field .dropdown-item {
  border-bottom: 1px solid #ccc;
  padding: 10px 15px !important;
}
.custom-form-slect-field .dropdown-item:last-child {
  border-bottom: none !important;
}
.Line_chart {
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.Bar_chart {
  border-radius: 10px;
  margin-top: 15px;
  background-color: white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding-left: 20px;
  padding-right: 20px;
}

.Bar_chart h4 {
  font-family: Inter !important;
  font-size: 20.05px !important;
  font-weight: 600 !important;
}
.Bar_chart h4 {
  padding-top: 20px;
  padding-left: 6px;
}

.side-bar .head h4 {
  height: 22px;
}
.main-app-wrapper .side-main-content .line-chart-rightside .side-bar.custom-sidebar-chart .head h4 {
  font-size: 20.05px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
  margin-bottom: 25px;
}
.top-bar {
  display: flex;
  top: 295px;
  left: 939px;
}
.top-bar p {
  font-size: 18.05px;
  margin-right: 20px;
  font-weight: 700;
  line-height: 24px;
  width: 80px;
  height: 24px;
  color: #949494;
}

.drop-down select {
  width: 346.01px;
  height: 56.16px;
  border-radius: 8.02px;
}

.drop-down section option {
  font-size: 16.05px;
}

.sidebar-section p {
  border-bottom: 1px solid #e2e2e2;
  color: #000000;
  font-size: 16.05px;
  font-weight: 500;
  line-height: 19.42px;
  padding-bottom: 10px;
  margin-top: 20px;
}
/* .tabs-custom-listing-holder:last-child {
  margin-bottom: 0px;
} */
.sidebar-section {
  width: 515px;
}
.custom-form-modal .form-control:focus {
  border: none;
  outline: none !important;
}
.journey-tab {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  align-items: baseline;
  justify-content: space-between;
}
.universities-tab {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  align-items: baseline;
  justify-content: space-between;
}
.playground-tab {
 display: block;
}
.playground-tab-holder{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  align-items: baseline;
  justify-content: space-between;
}
.playground_tab_holder{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  align-items: baseline;
  justify-content: space-between;
}
.side-bar {
  border-radius: 15px;
  background-color: white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  width: auto;
  height: auto;
  padding: 20px;
  cursor: pointer;
}
.active_Button {
  color: #47a0c7 !important;
}

iframe {
  display: none;
}
.error_notice {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.was-validated .form-control:valid {
  background-image: none !important;
}

.was-validated .form-control:invalid {
  background-image: none !important;
}

.as-link {
  cursor: pointer;
}

.img-holder .custom-profile-btn {
  cursor: pointer;
}

.MainCustomSidebarr ul.list-group {
  max-width: 100%;
}
.MainCustomSidebarr ul.list-group li {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.MainCustomSidebarr .list-group-item img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  object-fit: contain;
}
/*For Vertical Scroll*/
.line-chart-main-row {
  overflow-y: auto;
  overflow-x: hidden;
  /* height: 70vh; */
  padding-right: 8px;
  padding-bottom: 15px;
  margin-right: 0px !important;
}
.line-chart-main-row::-webkit-scrollbar {
  width: 8px;
}
.line-chart-main-row::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}
.line-chart-main-row::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}
.tabs-custom-listing-blocks .center {
  margin-left: 15%;
}
#view-modal .modal-header {
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 20px 0px;
}
#view-modal .modal-body {
  padding: 0px;
}
#view-modal .modal-header h4 {
  margin-bottom: 0px;
}
#view-modal #controlled-tab-example li button {
  padding-right: 0px;
}
#view-modal #controlled-tab-example {
   gap: 30px;
  display: flex;
}
#view-modal .modal-header .btn-close:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
#view-modal .modal-content {
  padding: 37px 37px 40px 37px;
}
#view-modal .modal-header .btn-close:hover {
  color: #000 !important;
}
#view-modal .modal-header .btn-close {
  color: #000 !important;
  padding: 0px !important;
}
#custom-modal .modal-content .modal-header {
  border: none;
  padding: 0px 0px 20px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.question-modal-block #custom-modal .modal-content .modal-header{
  padding: 0px 0px 13px 0px;
}
#custom-modal .modal-content .modal-header
#custom-modal .modal-content .modal-header i.fa-solid.fa-xmark {
  cursor: pointer;
  padding: 5px 7px;
  border-radius: 100%;
}
#custom-modal .modal-content .modal-header h4 {
  max-width: 350px;
}
#custom-modal .modal-content .modal-body {
  padding: 0px;
}
#custom-modal .modal-content .modal-header i.fa-solid.fa-xmark:hover {
  background-color: #038294;
  color: #ffffff;
}
#custom-modal .modal-content {
  padding: 37px 37px 40px 37px;
}
#custom-modal .modal-content .modal-header button.btn-close {
  width: 20px;
  height: 20px;
  font-size: 14px;
}
#custom-modal .modal-content .modal-header button.btn-close:hover {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
#custom-modal .modal-body h4 {
  font-weight: 500 !important;
}
#custom-modal .modal-body p {
  margin-bottom: 0px !important;
}
.our-custom-tab-content p {
  line-height: 1.4em;
  font-weight: 400;
  font-family: 'Inter';
  font-size: 17px;
  color:#000000;
}
.gets-started-form-holder .form-holder form {
  gap: 10px;
  display: flex;
  flex-direction: column;
}
.get-started-forget-form-holder .form-holder form {
  gap: 25px;
}
.gets-started-form-holder
  .form-holder
  form
  button.btn.btn-primary.gets-form-btn {
  max-width: 200px;
}
.gets-started-form-holder label {
  margin-bottom: 10px !important;
}
#view-modal .Profile-from {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
#view-modal .Education-from {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
#view-modal .Personal-information-from {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
#view-modal .modal-footer {
  margin-top: 20px;
}
#join-modal .modal-header {
  border: 0px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
}
#join-modal .modal-header h4 {
  margin-bottom: 0px !important;
}
#join-modal .modal-body {
  padding: 0px;
}
#join-modal .modal-body .modal-tab-block form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
#join-modal .modal-body .modal-tab-block form .form-group label {
  color: #000;
  font-weight: 500 !important;
  font-size: 17px;
  margin-bottom: 10px !important;
  font-family: "Inter";
}
#join-modal .modal-content {
  padding: 37px 37px 40px 37px;
}
#join-modal .modal-body .form-group button.btn.btn-primary {
  background: #45aad6;
  color: #fff;
  border: none;
  outline: none;
  transition: all 0.3s ease-in-out;
  min-width: 100px !important;
  height: 50px !important;
  border-radius: 8px !important;
  font-weight: 700 !important;
  font-size: 17px !important;
  line-height: 20px !important;
  margin: 0px;
}
#join-modal .modal-header h4 {
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  font-family: "Inter";
}
#join-modal .modal-header i.fa-solid.fa-xmark {
  font-size: 20px;
  font-weight: 900;
  cursor: pointer;
  padding: 5px 7px;
  border-radius: 100%;
}
#join-modal .modal-header i.fa-solid.fa-xmark:hover {
  background-color: #038294;
  color: #ffffff;
}
#view-modal .modal-header i.fa-solid.fa-xmark {
  padding: 5px 7px;
  font-size: 20px;
  border-radius: 100%;
  cursor: pointer;
}
#view-modal .modal-header i.fa-solid.fa-xmark:hover {
  background-color: #0092a6;
  color: #ffffff;
}
#view-modal .modal-header h4 {
  margin-bottom: 0px;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 24px !important;
}
#sportmanship-modal .modal-header {
  justify-content: space-between;
  border: none !important;
  display: flex;
  align-items: center;
  padding: 0px 0px 11px 0px;
}
#sportmanship-modal .modal-body {
  padding: 0px;
}
#sportmanship-modal .modal-content {
  padding: 37px 37px 40px 37px;
}
#sportmanship-modal .modal-header h4 {
  margin-bottom: 0px;
}

#sportmanship-modal .modal-header i.fa-solid.fa-xmark {
  cursor: pointer;
  padding: 5px 7px;
  border-radius: 100%;
  font-size: 20px;
}
#sportmanship-modal .modal-header i.fa-solid.fa-xmark:hover {
  background-color: #0092a6;
  color: #ffffff;
}
#custommodal2 .modal-header {
  display: flex;
  justify-content: space-between;
  border: none;
  align-items: center;
  padding: 0px 0px 20px 0px;
}
#custommodal2 .modal-header i.fa-solid.fa-xmark {
  cursor: pointer;
  padding: 5px 7px;
  border-radius: 100%;
}
#custommodal2 .modal-header i.fa-solid.fa-xmark:hover {
  background-color: #038294;
  color: #ffffff;
}
#custommodal2 .modal-content {
  max-width: 530px;
  margin: 42px auto 0px;
  padding: 37px 37px 40px 37px;
}
#custommodal2 .modal-content .modal-body {
  padding: 0px;
}
#custommodal2 .modal-content .modal-footer {
  padding-left: 0px;
}
#custommodal2 .modal-header h4 {
  font-size: 20px !important;
  font-weight: 700 !important;
  font-family: "Inter" !important;
  line-height: 24px !important;
  margin-bottom: 0px;
}
.gets-started-form-holder .form-group .invalid-feedback {
  margin-top: 3px;
  font-weight: 500;
  font-size: 15px;
}
.menu_Icon{
  display: none;
}
#custom-modal .modal-content .modal-header h4 {
  margin-bottom: 0px;
}
/* .table-responsive {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-right: 11px;
  max-height: 633px;
} */
#custom-modal .modal-content .modal-body .custom-form-modal .form-group #multiSelectDropdown {
  width: 100%;
}
#custom-modal .modal-content .modal-body .custom-form-modal .dropdown.show .dropdown-menu.show {
  width: 100%;
  top: 0px !important;
}
#custom-modal .modal-content .modal-header i.fa-solid.fa-xmark {
  cursor: pointer;
  padding: 5px 7px;
  border-radius: 100%;
  font-size: 20px;
}
.question-modal-block #custom-modal{
  display: flex;
  justify-content: center;
}
.question-modal-block #sportmanship-modal{
  display: flex;
  justify-content: center;
}
#sportmanship-modal .modal-body .sportmanship-from .form-group .form-group #multiSelectDropdown {
  width: 100%;
  margin-top: 0px;
}
#sportmanship-modal .modal-body .sportmanship-from .form-group .form-group .dropdown.show .dropdown-menu.show {
  width: 100%;
}
.line-chart-main-container.container {
  max-width: 100% !important;
}
input[type=number] {
  -moz-appearance: textfield; 
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;  /* Hide the default arrows in Chrome and Safari */
  
}
.universities-tab .tab-content .table-responsive.all-tabs-table-holder {
  max-height: 357px;
}
/* .was-validated .form-control:valid:focus {
  box-shadow: none !important;
} */
#resume-pdf .modal-content .modal-header {
  padding: 0px 0px 20px 0px;
  border: none;
  display: flex;
  justify-content: end;
  align-items: center;
}
#pdf_content table thead tr th h2 {
  white-space: nowrap;
}
#resume-pdf .modal-content .modal-header i.fa-solid.fa-xmark {
  padding: 5px 7px;
  cursor: pointer;
  font-size: 20px;
  border-radius: 100%;
}
#resume-pdf .modal-content .modal-header i.fa-solid.fa-xmark:hover {
   background-color: #038294;
   color: #ffffff;
}
#resume-pdf .modal-content {
  padding: 37px 37px 40px 37px;
}
#view-modal .modal-tab-block form #controlled-tab-example-tabpane-education .Education-from .form-group input[type="date"]::placeholder {
  color: #a3a3a3 !important;
}
#view-modal #controlled-tab-example li button {
  padding-bottom: 0px;
}
/* .universities-tab .table-responsive.all-tabs-table-holder {
  max-height: 353px;
} */
#custommodal2 .form-group .custom-form-slect-field .dropdown button {
  box-shadow: 0px 1px 4.01px 0px #00000040 !important;
}
#custom-modal .modal-body div #weeksPerYear:focus {
  box-shadow: 0px 1px 4.01px 0px #00000040 !important;
}
#sportmanship-modal .modal-body .form-group.form-group1 .form-group-contain #weeksPerYear:focus {
  box-shadow: 0px 1px 4.01px 0px #00000040 !important;
}
#sportmanship-modal .modal-body .form-group.form-group1 .form-group-contain #hoursPerWeek:focus {
  box-shadow: 0px 1px 4.01px 0px #00000040 !important;
}
#custom-modal .modal-body form.custom-form-modal.was-validated div #hoursPerWeek:focus {
  box-shadow: 0px 1px 4.01px 0px #00000040 !important;
}
#view-modal .modal-tab-block form .form-group .education_value{
  color:black!important;

}
#view-modal .modal-tab-block form .form-group .education_epmty{
  color: rgba(36, 34, 34, 0.53) !important;
}

#view-modal .modal-tab-block form .form-group #name2 {
  height: 80px;
}




#pricing-modal .modal-content .modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0px;
  position: relative;
}
#pricing-modal .modal-content {
  padding: 30px; 
  max-width: 550px;
  padding-top: 25px;
}
#pricing-modal .modal-content .modal-body {
  padding: 0px;
}
#pricing-modal .modal-content .pricing-info-block #controlled-tab-example {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}
#pricing-modal .modal-content .pricing-info-block #controlled-tab-example li #controlled-tab-example-tab-annually {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  padding: 0px !important;

}
#pricing-modal .modal-content .pricing-info-block #controlled-tab-example li #controlled-tab-example-tab-one-time {
  border: none;
  padding: 0px;
  font-size: 18px;
  line-height: 21px;
  justify-content: center;
  align-items: center;
}
#pricing-modal .modal-content .pricing-info-block #controlled-tab-example li #controlled-tab-example-tab-one-time:hover{
  border:none;
  background: transparent;
}
#pricing-modal .modal-content .pricing-info-block .nav-link.active div span{
  background: none;
  color: #0092A6!important;
}
#pricing-modal .modal-content .pricing-info-block .nav-link div span{
  color: #a3a3a3!important;
}
#pricing-modal .pricing-info-block .nav-tabs .nav-link.active span{
  font-size: 18px;
  line-height: 21px;
}
#pricing-modal .modal-content .modal-header h2 {
  margin-bottom: 0px;
  font-size: 22px;
  line-height: 24px;
}
#pricing-modal .modal-content .modal-header i.fa-solid.fa-xmark {
  cursor: pointer;
  padding: 5px 7px;
  border-radius: 100%;
  font-size: 20px;
  background-color: #0092a6;
  color: #ffffff;
  cursor: pointer;
  position: absolute;
  right: 0px;
  padding: 5px 7px;
  font-size: 20px;
}
#pricing-modal .modal-content .modal-header i.fa-solid.fa-xmark:hover {
  background-color: #038294;
}
#pricing-modal .modal-content .payment-list-box ul li:last-child {
  margin-bottom: 0px !important;
}
#pricing-modal .modal-content .payment-list-box ul {
  margin-bottom: 20px;
  min-height: auto;
}
#pricing-modal .modal-content .payment-tab-content {
  margin-right: 0px;
}
.MainCustomRow.row .side-main-content .user-tble-contain.container {
  padding-left: 10px;
  padding-right: 13px;
  padding-top: 10px;
}
.payment-circular {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: radial-gradient(circle, #F9FBE7, #F9FBE7);
  /* border-radius: 15px; */
  padding: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

.payment-text {
  font-size: 18px;
  color: black;
  margin-top: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.test-scenarios-holder h2{
  font-family: 'Inter';
  font-size: 2rem;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  margin-bottom: 20px;
  color: #FFFFFF;
}
.test-scenarios-holder ol li{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  text-align: center;
}
.test-scenarios-holder ol{
text-align: left;
line-height: 1.4; 
}

#newfrommodal .modal-content .link-section div ol li ::before {
  width: 2em;
  height: 2em;
}
.bookmark-icon-holder span svg {
  width: 20px;
  height: 20px;
}
nav.navbar.navbar-expand-lg.navbar-light {
  padding: 2px 20px;
  background: rgba(255, 255, 255, 1) !important;
}
.navbar-expand-lg .navbar-collapse .dropdown-toggle::after {
  display: none;
}
.navbar-expand-lg .navbar-collapse {
  position: relative;
  overflow: visible;
}
#userDropdown i.fa-solid.fa-chevron-down {
  width: 20px;
  height: auto;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
}
.navbar-expand-lg .navbar-nav li.nav-item.dropdown .shadow-lg {
  position: absolute !important;
  width: 100%;
  padding: 0px;
  list-style: none;
  cursor: pointer;
  border: none !important;
  border-radius: 8px;
  margin-top: 3px !important;
  background-color: #fff !important;
  box-shadow: 0 1px 4.01px 0 rgba(0, 0, 0, .25098039215686274) !important;
  z-index: 9;
}
.dashboard-tab-section .tab-dropdown-listing .dashboard-dropdowns.dropdown #dropdown-basic {
  min-width: 250px;
}
.dashboard-name-section .tab-dropdown-listing .dropdown-menu.show {
  min-width: 250px !important;
}
.navbar-expand-lg .navbar-nav li.nav-item.dropdown .shadow-lg li {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ccc;
  font-family: Poppins;
}
.navbar-expand-lg .navbar-nav li.nav-item.dropdown .shadow-lg li:hover {
  background-color: #0092a6;
  color: #fff;
}
.navbar-expand-lg .navbar-nav li.nav-item.dropdown .shadow-lg li:hover:first-child{
  border-radius: 8px 8px 0px 0px;
}
.navbar-expand-lg .navbar-nav li.nav-item.dropdown .shadow-lg li:hover:last-child{
  border-radius: 0px 0px 8px 8px;
}
.dropdown-college {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.line-chart-main-container-user {
  background: #E2F2ff;
  padding: 24px;
}
.MainCustomSidebarr ul.list-group {
  width: 278px !important;
}
.progress-section .card {
  background-color: #fff !important;
  border-radius: 15px;
  border: none;
}
.dropdown-college h5 {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  align-items: center;
  display: flex;
  margin-bottom: 0px;
  color: rgba(70, 78, 95, 1);
}
.progress-section .card-body .mt-3 label {
  display: inline-block;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.card-body .progress-1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.progress .progress-bar {
  background: rgba(1, 146, 166, 1);
}
.progress.bg-light {
  background: rgba(237, 237, 237, 1) !important;
}
.progress-dropdown .dropdown {
  border: 1px solid #dee2e6;
  border-radius: 5px;
}
.skip-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.progress-section {
  padding: 0px;
}
.dashboard-listing {
  justify-content: space-between;
  align-items: center;
}
.progress-section .card .card-body {
  padding: 35px;
}
.dashboard-listing .top-bar {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}
.dashboard-listing .top-bar p {
  width: fit-content;
}
.dashboard-label form .form-group {
  background: rgba(236, 242, 255, 1);
  width: 100%;
  padding: 12px;
  border-radius: 5px;
  height: 44px;
}
.dashboard-label form .form-group .col {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 50px;
  text-align: left;
  margin: 0px;
  white-space: nowrap;
  color: rgba(70, 78, 95, 1);
}
.progress-3 .dashboard-tab-section .dashboard-dropdowns.dropdown #dropdown-basic {
  min-width: 250px;
}
.progress-3 .dashboard-tab-section .dashboard-dropdowns.dropdown .dropdown-menu.show {
  min-width: 250px;
}
.dashboard-listing .custom-form-slect-field {
  width: 50%;
  max-width: 100%;
  margin-bottom: 0px;
}
.dashboard-listing .top-bar p {
  margin: 0px;
}

/* ========= */
.card-view {
  background-color: #ffff;
  padding: 20px;
  border-radius: 15px;
}
.card-view .card-body .card-title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: rgba(70, 78, 95, 1);
}
.card-view .card-body .card-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color:  rgba(70, 78, 95, 1);
  margin-bottom: 0px;
  text-overflow: ellipsis;
  max-width: 230px;
  display: block;
  overflow: hidden;
  user-select: none;
}
.card-view .card-body .card-content .card-link {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #0092A6;
  margin-bottom: 0px;
  user-select: none;
}
.card-view .card-body .card-content .card-links {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #0092A6;
}
.card-view .card-body .card-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
#pricing-modal .modal-content .modal-header i.fa-solid.fa-xmark {
  top: -3px;
}
/* calander */
.calendar {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
}
.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 1.2em;
}

.calendar-header button {
  border: none;
  cursor: pointer;
  width: 36px;
  background-color: transparent;
}
.progress-user {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.calendar-header button img {
  position: relative;
  top: -2px;
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 10px;
  font-weight: bold;
  color: #777;
}

.calendar-day {
  font-family: Poppins;
  font-size: 9px;
  font-weight: 400;
  line-height: 13.5px;
  text-align: center;
  padding: 10px 0;
}

.calendar-cells {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 8px;
}

.calendar-cell {
  background-color: #f5f5f5;
  cursor: pointer;
  transition: background-color 0.2s;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 25.5px;
  text-align: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: rgba(70, 78, 95, 1);
}

.calendar-cell.selected {
  background: #0092A6;
  color: #fff;
}
.calendar-cell.selected:hover {
  background: #0092A6!important;
}
.calendar-cell:hover {
  background-color: #e9ecef;
}

.calendar-cell.empty {
  background-color: transparent;
  cursor: default;
}
.calendar-header span {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  color: rgba(70, 78, 95, 1);
}
/* ScholarshipCard.css */
.card {
  background-color: #f8f9fa !important; 
}
.shadow-sm {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1) !important;
}
.scholarship-calander .scholarship {
  font-family: Poppins;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  text-align: left;
  color: rgba(70, 78, 95, 1) !important;
}
.scholarship-calander .text-muted span.text-primary {
  color: rgba(1, 146, 166, 1) !important;
}
.scholarship-calander h5 {
  font-family: Poppins;
  font-size: 9px;
  font-weight: 500;
  line-height: 11px;
  margin: 0px !important;
  text-align: left;
  color: rgba(70, 78, 95, 1);
}
.scholarship-calander .card {
  background: rgba(245, 248, 255, 1) !important;
  border: none !important;
  height: 60px;
  padding: 8px 12px !important;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.no-recorde-upscholarship {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}
.progress {
  background: rgba(237, 237, 237, 1);
}
.scholarship-calander .card .d-flex.justify-content-between.align-items-center .text-muted {
  font-family: Poppins;
  font-size: 7px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: rgba(123, 145, 176, 1);
}
.scholarship-calander .d-flex.justify-content-between.align-items-center {
  line-height: 4px;
  width: 100%;
  gap: 5px;
}
.scholarship-calander .card .d-flex.justify-content-between.align-items-center p {
  font-family: Poppins;
  font-size: 9px;
  font-weight: 500;
  line-height: 11px;
  text-align: left;
  color: rgba(70, 78, 95, 1);
}
.dashboard-image {
  background-image: url('../images/Mask.png');
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  height: 230px;
  position: relative;
  width: 100%;
}
.dashboard-image .card-body p {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  margin-bottom: 0px;
  color: rgba(255, 255, 255, 1);
}
.dashboard-image .card {
  color: white !important;
  background: transparent !important;
  border: none !important;
  padding: 15px !important;
}
.dashboard-image .card-body .card-title {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  margin-bottom: 0px;
  color: rgba(255, 255, 255, 1);
}
.dashboard-image .card-body {
  padding: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.dashboard-image .card-body .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 36px;
  padding: 7px 23px 7px 23px;
  border-radius: 7px;
  text-align: center;
  width: 40%;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  border: none;
  background: rgba(206, 173, 44, 1);
}
.card-body .progress-1 label {
  color: rgba(70, 78, 95, 1);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

}
.dashboard-image .card-body .btn:active {
  background: rgba(206, 173, 44, 1);
}
.Majors-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 30px;
}
.Majors-section .form-group {
  width: 48%;
}
.Majors-section .btn.btn-primary {
  display: block;
  background: #45AAD6;
  color: #fff;
  border: none;
  outline: none;
  transition: all 0.3s ease-in-out;
  min-width: 100px !important;
  height: 50px !important;
  border-radius: 8px !important;
  font-weight: 700 !important;
  font-size: 17px !important;
  line-height: 20px !important;
  margin-top: 30px;
}
#custom-modal .modal-content .modal-body .custom-form-modal .dropdown.show .dropdown-menu.show .form-check:last-child {
  border-bottom: 0px !important;
}
.custom-modal1 .custom-form-modal .dropdown .dropdown-menu.show .form-check:hover:first-child {
  border-radius: 4px 4px 0px 0px;
}
.custom-modal1 .custom-form-modal .dropdown .dropdown-menu.show .form-check:hover:last-child {
  border-radius: 0px 0px 4px 4px;
}

nav.navbar.navbar-expand-lg.navbar-light .navbar-brand {
  position: relative;
}
a.navbar-brand::before {
  content: "";
  position: absolute;
  border-right: 2px solid #fff;
  top: -28px;
  width: 20px;
  left: 225px;
  visibility: visible;
  transform: rotate(180deg);
  display: none;
}
.col-md-9.col-sm-9.line-chart-rightside.pe-4 .custom-sidebar-chart {
  height: 500px !important;
}
.autocomplete-search-container .form-control {
  background-color: rgb(249, 249, 249);
  height: 60px;
  padding: 15px 20px !important;
}
.autocomplete-search-container .form-control::placeholder {
  color: #ccc;
}
.autocomplete-search-container ul li:last-child {
  border-bottom: none !important;
}
.tags-input-resume-holder {
  width: 100%;
}
.calendar-header button svg {
  color: rgba(70, 78, 95, 1);
}
.scholarship-calander .card svg {
  color: rgba(70, 78, 95, 1);
  width: 13px;
  height: auto;
}
.circle-icon-child-holder > span {
  color: #c0c0c0;
  font-family: Poppins;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  font-size: 14px;
  margin-bottom: 0px;
  word-break: break-all;
}
.progress-dropdown .dropdown #dropdown-basic svg {
  color: rgb(133, 136, 138);
}
.two-line-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3em; /* Adjust based on your line-height */
  line-height: 1.5em; /* Adjust based on your design */
}
.one-line-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 2em; 
  line-height: 1.5em;
  color: #c0c0c0; /* This will color both the text and the ellipsis */
}

#profile-dropdown .dropdown-menu .dropdown-item:focus-visible {
  outline: none !important;
}
.break-long-words {
  white-space: normal; /* Allow text to wrap onto the next line */
  word-break: break-word; /* Break long words if necessary */
  overflow-wrap: break-word; /* Force the text to wrap inside the container */
}

.custom_new_popover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 8px; /* Optional */
  filter:unset !important
  
}