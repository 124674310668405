/*Top Bar Styling*/
.main-top-bar{
    background-color: #E6F2F4;
    padding: 0px;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
}
.custom-form-modal .form-control:focus{border: none;}

.gets-started-form-holder.custom-form-slect-field .dropdown button{
    width:99%!important;
    height: 43px!important;
  }
.search-input[type="search"]::-webkit-search-cancel-button {
  appearance: none; 
}
/*Sidebar Styling*/
.MainCustomSidebarr {
    width: auto;
    transition: max-height 0.5s ease-out;
    overflow: hidden;
    min-width: 247px;
}
.MainCustomSidebarr.closed {
    height: 100%;
    max-height: 0px;
    display: none;
}
.main-app-wrapper .main-top-bar .img-holder button {
    background: #ffd660 !important;
    height: 50px;
    min-width: 150px;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #000 !important;
    border-radius: 8px !important;
    border: none !important;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.main-app-wrapper .main-top-bar .img-holder button:hover {
background: #ffe28f !important;
}
.MainCustomSidebarr.Dashboard{
    padding-top: 133px !important ;
  
}
.MainCustomSidebarr .list-group-item,
.MainCustomSidebarr .list-group-item.active{
    color:#fff;
    margin-bottom: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;

}
.saved-content-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header-holder .select-field-block {
    display: flex !important;
    justify-content: space-between;
    width: 94.3%;
}
.custom-modal2 .form-group1 .form-group-contain input::placeholder {
    color: #a3a3a3 !important;
}
form.custom-form-modal div input::placeholder {
    color: #a3a3a3 !important;
}
.MainCustomSidebarr .list-group-item.active{
    background: rgba(255, 255, 255, 0.1) !important;
    color: #fff;
}
.MainCustomSidebarr .list-group-item.active::before {
    content: '';
    position: absolute;
    left: 0;
    margin: auto auto !important;
    background-color: #fff;
    width: 5px;
    height: 44px;
    top: 5px;
    border-radius: 0px 5px 5px 0px;
}
.custom-sticky-side{
    padding-right: 0px!important;
    background: #272727;
    z-index: 1;
}
.side-main-content{
    padding-left: 0px!important;
    padding-right: 0px!important;
    background: #F5F7F9;
}
/*Header Css*/
.header-holder{
    background: #0092A6;
    padding: 20px 53px;
    margin-bottom: 15px;
}
.header-holder.dasboard-page {
    margin-bottom: 0px;
}
.faq-section #accordion .card textarea:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: #ccc !important;
}
.custom-form-slect-field .dropdown button{
    width: 100% !important;
    margin-right: 22px!important;
    height: 50px;
    margin-top: 0px !important;
    border-radius: 8px!important;
    color: rgba(70, 78, 95, 1) !important;
    font-size: 16px!important;
    line-height: 19px!important;
    font-weight: 500!important;
    font-family: 'Inter'!important;
    border: none!important;
    padding-left: 15px!important;
    padding-right: 15px!important;
    padding-top: 0!important;
    padding-bottom: 0!important;
    background: #fff;
    text-align: left;
    position: relative;
    white-space: normal;
}
.custom-form-slect-field .dropdown-toggle{position: relative!important;}
.custom-form-slect-field .dropdown button:focus{background: #fff;}
.custom-form-slect-field .dropdown-toggle::after {
    display: inline-block;
    margin-left: 6.5em;
    vertical-align: .255em;
    content: "";
    border: solid rgba(70, 78, 95, 1);
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
    position: absolute;
    left: auto;
    right: 18px;
    top: 18px;
}
.custom-form-slect-field .show.dropdown .dropdown-menu.show {
    display: block;
    width: 100%;
    padding: 0px;
}
.custom-form-slect-field .dropdown-item{
    color: #000!important;
    padding: 10px;

}
.custom-form-slect-field .dropdown-item:hover{
    background: #0092A6;
    color: #fff!important;
    
}
.custom-form-slect-field .dropdown-item:first-child {
    border-top-right-radius: 4px !important;
    border-top-left-radius: 4px;
}
#custommodal2 .custom-form-slect-field .dropdown-item:first-child,
#custommodal2 .custom-form-slect-field .dropdown-item:last-child {
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px;
}
#custommodal2 .custom-form-slect-field .dropdown-item{
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px;
}
.custom-form-modal .form-control:focus-visible {
    outline: none !important;
}
form.custom-form-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 20px;
}
.question-modal-block #custom-modal form.custom-form-modal{
    gap: 10px;
}
.custom-form-modal button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    width: 30%;
    background: #45AAD6;
    border: none;
    font-weight: 700;
    line-height: 20px;
    padding: 10px 15px;
    font-family: 'Inter';
    font-size: 17px;
}
#pricing-modal .custom-form-modal .pricing-info-block button{
    background: transparent;
    border: none;
    box-shadow: none;
    justify-content: center;
}
.dashboard-name-section {
    padding-bottom: 15px;
}
.navbar-expand-lg .navbar-nav li.nav-item.dropdown .shadow-lg li:last-child {
    border-bottom: none !important;
}
#pricing-modal .modal-content .modal-header .one-time-tab {
    padding-left: 26px;
}
.dashboard-dropdowns.show.dropdown .dropdown-menu.show a:last-child {
    border-bottom: none !important;
}
#pricing-modal .custom-form-modal .pricing-info-block .dollar-list-contain button{
    background: rgba(255, 214, 96, 1);
    text-align: center;
    justify-content: center;
    margin: 0 auto;
}
#pricing-modal .payment-list-box strong{margin-bottom: 12px;}
#pricing-modal .custom-form-modal .pricing-info-block button:hover{background: transparent!important;}
#pricing-modal .payment-list-box{
    margin-top: 0px;
    min-height: 360px;
    width: 70%;
    margin: auto;
}
#pricing-modal .payment-list-box ul li{
    font-size: 16px;
    line-height: 19px;
}
#pricing-modal .payment-tab-holder{
    display: block;
}
#pricing-modal .payment-list-box{
    max-width: none;
    padding-top: 15px;
    padding-bottom: 15px;

}
#pricing-modal .custom-form-modal{display: block;}
.custom-form-modal button
.custom-form-modal .form-label {
    font-size: 17px;
}
.custom-form-modal .form-control {
    box-shadow: 0px 1px 4.01px 0px #00000040;
    height: 50px;
    border-radius: 8px;
    background: #ffffff;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Inter';
    padding: 0px 15px;
}
.side-main-content .main-tabs-block #controlled-tab-example .nav-item button {
    background: transparent;
}
.custom-form-modal button:hover {
    background: #45AAD6 !important;
}
.custom-form-slect-field .dropdown-item:last-child {
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 4px;
}
.custom-form-slect-field:nth-child(2) .dropdown-toggle::after{
    margin-left: 2.7em;
    position: absolute;
    left: auto;
    right: 18px;
}
.custom-form-slect-field:nth-last-child(2){
    margin-right: 113px;
}
.ideal-header{
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    color:#fff;
    display: flex;
    flex-direction: column;
}
/*Journey Css*/
.main-tabs-block{
    padding-left: 53px;
    padding-right: 15px;
}
.main-tabs-contain .nav.nav-tabs{
    border-bottom: none;
}
.playground-tab h4 {
    margin-bottom: 15px !important;
}
.playground-tab p {
    line-height: 1.4em;
    font-size: 17px;
}
.main-tabs-contain .nav-tabs .nav-link.active,
.main-tabs-contain .nav-tabs .nav-link{
    color: #47A0C7;
    font-size: 20px;
    font-weight: 700!important;
    line-height: 24px;
    outline: none;
    border: none;
    box-shadow: none;
    text-shadow: none;
    padding-left: 0;
    padding-right: 35px;
    padding-top: 0px;
    background: #f5f7f9;
}
.line-chart-leftside .Line_chart h4{padding-top: 30px;}
.side-bar.custom-sidebar-chart{padding: 30px;}
.side-bar.custom-sidebar-chart .head h3{margin-bottom: 25px;}
.main-tabs-contain .nav-tabs .nav-link{color: #949494;}
.our-custom-tab-content h4{
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000;
    margin-bottom: 15px;
}
.tabs-custom-listing-holder .circle-icon-holder > h4 span,
.alltabs-list-table  h4 span{
    margin-left: 20px;
    font-size: 20px;
    cursor: pointer;
}
.alltabs-list-table thead tr th{
    padding-top: 0px;
    padding-bottom: 20px;
    padding-right: 0px;
}
.alltabs-list-table tbody tr td{
    padding-top: 10px;
    padding-bottom: 10px;
    vertical-align: middle;
}
.alltabs-list-table tbody tr td:last-child{
    text-align: right;
    padding-right: 10px;
}
.tabs-no-btn-tbl-holder tbody tr td:last-child{
    text-align: left;
}
.tabs-no-btn-tbl-holder.table-responsive{padding-right: 0px;}
.alltabs-list-table tbody tr td .bookmark-icon-holder{
    display: block;
    padding-right: 0px;
}
.alltabs-list-table tbody tr td .bookmark-icon-holder .btn{margin-top: 0px;}
.alltabs-list-table tbody tr td .circle-icon-holder h4{
    margin-bottom: 0px!important;
    word-break: break-word;
}
.alltabs-list-table tbody tr td,
.alltabs-list-table thead tr th,
.alltabs-list-table tbody tr td{padding-left: 0px;}
.alltabs-list-table tbody tr:last-child td{border-bottom: 0px;}
.bookmark-group h4 {
    margin-bottom: 0px !important;
}
tbody, td, tfoot, th, thead, tr {
    background-color: transparent !important;
}
.universities-list table.table tr {
    border-bottom: 1px solid #e2e2e2;
}
.universities-list tr.table-heading h3 {
    border: none !important;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
}
.universities-list tr.table-heading {
    border: none !important;
    margin-bottom: 20px !important;
}
.universities-list .table tbody tr td h4 span svg {
    color: #4FB5EF;
}
.universities-list .table tbody tr td h4 {
        padding-bottom: 0px;
        margin-bottom: 0px;
}
.universities-list .table tbody tr td {
    padding: 20px 0px 0px 0px !important;
}
.universities-list .table tbody tr td button {
    margin-bottom: 20px;
}
.table-responsive {
    -webkit-overflow-scrolling: touch;
    padding-right: 11px;
}
#view-modal .modal-tab-block form .form-group label {
    color: #000000;
    font-weight: 500 !important;
    font-size: 17px;
    line-height: 20px;
}
#view-modal .modal-tab-block form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
#view-modal .form-group .dropdown-menu.show {
    margin-top: 0px;
}
#sportmanship-modal .modal-content .form-group label {
    font-weight: 500 !important;
    font-size: 17px;
    line-height: 20px;
    font-family: 'Inter';
}
#sportmanship-modal .sportmanship-from {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
}
#sportmanship-modal .modal-body .form-group.form-group1.Involved-from .form-group:nth-child(2) .dropdown.show .dropdown-menu.show {
    margin-top: -19px !important;
    padding: 0px;
}
#sportmanship-modal .modal-body .form-group.form-group1.Involved-from .form-group:nth-child(2) .dropdown.show .dropdown-menu.show .form-check {
    border-bottom: 1px solid #ccc;
    padding: 10px 10px 10px 15px !important;
    color: #000000;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    margin-left: 0px !important;
}
#sportmanship-modal .modal-body .form-group.form-group1.Involved-from .form-group:nth-child(2) .dropdown.show .dropdown-menu.show .form-check:last-child {
        border-bottom: none !important;
        border-bottom-right-radius: 4px !important;
        border-bottom-left-radius: 4px;
}
#sportmanship-modal .modal-body .form-group.form-group1.Involved-from .form-group:nth-child(2) .dropdown.show .dropdown-menu.show .form-check:first-child{
    border-top-right-radius: 4px !important;
    border-top-left-radius: 4px;
}
.setting-txt-placeholder.placeholder-container{align-items: center;}
.setting-listing-holder.scrolled .tabs-custom-listing-holder .placeholder-block .setting-txt-placeholder.placeholder-container .custom-form-slect-field .show.dropdown .dropdown-menu {
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
}
.setting-listing-holder.scrolled .tabs-custom-listing-holder .setting-txt-placeholder.placeholder-container .custom-form-slect-field button {
    color: #000 !important;
}
.setting-listing-holder.scrolled .tabs-custom-listing-holder .setting-txt-placeholder.placeholder-container .custom-form-slect-field button::after{
    border-color: #000 !important;
}
.setting-listing-holder.scrolled .tabs-custom-listing-holder .placeholder-block .setting-txt-placeholder.placeholder-container .custom-form-slect-field .show.dropdown .dropdown-menu::-webkit-scrollbar {
    width: 8px;
  }
.setting-listing-holder.scrolled .tabs-custom-listing-holder .placeholder-block .setting-txt-placeholder.placeholder-container .custom-form-slect-field .show.dropdown .dropdown-menu::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  .setting-listing-holder.scrolled .tabs-custom-listing-holder .placeholder-block .setting-txt-placeholder.placeholder-container .custom-form-slect-field .show.dropdown .dropdown-menu::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
  }
#sportmanship-modal .modal-body .form-group.form-group1.Involved-from .form-group:nth-child(2) .dropdown.show .dropdown-menu.show .form-check:hover {
    background: #0092A6 !important;
    color: #fff !important;
}
#sportmanship-modal .modal-body .form-group.form-group1.Involved-from .form-group:nth-child(2) .dropdown.show .dropdown-menu.show .form-check-input:checked {
    background-color: #45AAD6;
    border-color: #45AAD6;
    box-shadow: none;
    outline: none;
}
#sportmanship-modal .sportmanship-from .custom-form-slect-field .dropdown-menu.show {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 200px;
    margin-top: 0px;
}
#sportmanship-modal .sportmanship-from .custom-form-slect-field .dropdown-menu.show::-webkit-scrollbar {
    width: 8px;
  }
  #sportmanship-modal .sportmanship-from .custom-form-slect-field .dropdown-menu.show::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  #sportmanship-modal .sportmanship-from .custom-form-slect-field .dropdown-menu.show::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
  }
  .dashboard-dropdowns.show.dropdown .dropdown-menu.show::-webkit-scrollbar {
    width: 8px;
  }
  .dashboard-dropdowns.show.dropdown .dropdown-menu.show::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  .dashboard-dropdowns.show.dropdown .dropdown-menu.show::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
  }
#view-modal .form-group h4 {
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 21px !important;
    margin-bottom: 20px;
}
#view-modal .modal-tab-block form .form-group #email-input-disabled {
    cursor: not-allowed !important;
}
#custommodal2 .modal-content .modal-tab-block #controlled-tab-example-tabpane-general .form-group h5 {
    font-weight: 500;
    font-family: 'Inter';
    font-size: 17.5px;
    line-height: 20px;
}
#custommodal2 .modal-content .modal-tab-block #controlled-tab-example-tabpane-general .form-group label {
    color: #000000;
    font-weight: 500 !important;
    font-size: 17.5px;
    line-height: 20px;
}
.value-modal-coustom2 h5 {
    font-weight: 600;
    font-family: 'Inter';
    font-size: 18px;
    line-height: 20px;
}
#custommodal2 .modal-content .modal-tab-block #controlled-tab-example-tabpane-general .custom-tabs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
}
.value-modal-coustom2 {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.value-modal-coustom2 .form-numb-group-holder .form-number-group .form-label {
    font-weight: 500 !important;
    font-size: 17px !important;
    line-height: 20px;
    font-family: 'Inter';
}
.value-modal-coustom2 .form-numb-group-holder .form-number-group span.number-percent {
    font-size: 16px;
    line-height: 20px;
    font-family: 'Inter';
    font-weight: 500;
}
#custommodal2 .modal-content .modal-tab-block #controlled-tab-example-tabpane-general .form-group button#dropdown-basic {
    margin-right: 0px;
}
.custom-form-modal #multiSelectDropdown {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 15px !important; 
    padding-right: 15px !important;
}
.custom-modal1 .custom-form-modal .form-group #multiSelectDropdown:hover {
    background-color: transparent !important;
}
.custom-modal1 .custom-form-modal .dropdown .dropdown-menu.show {
    position: absolute;
    top: 0px;
    padding: 0px !important;
}
.custom-modal1 .custom-form-modal .dropdown .dropdown-menu.show .form-check .form-check-input.is-valid:checked, .was-validated .form-check-input:valid:checked {
    background-color: #45AAD6 !important;
    border-color: #45AAD6 !important;
}
.custom-modal1 .custom-form-modal .dropdown .dropdown-menu.show .form-check .form-check-input.is-valid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label {
    color: #000 !important;
}
.form-check-input.is-valid, .was-validated .form-check-input:valid {
    border-color: #45AAD6 !important;
}
.form-check-input.is-valid:focus, .was-validated .form-check-input:valid:focus {
    box-shadow: none !important;
    outline: none !important;
}
.custom-modal1 .custom-form-modal .dropdown .dropdown-menu.show .form-check {
    margin: 0px !important;
    border-bottom: 1px solid #ccc;
    padding: 10px 10px 10px 15px !important;
    color: #000000;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    line-height: 28px;
    height: 50px;
}
.custom-modal1 .custom-form-modal .dropdown .dropdown-menu.show .form-check .form-check-input {
    margin: 0px !important;
    cursor: pointer;
    font-size: 18px;
}
.custom-modal1 .custom-form-modal .dropdown .dropdown-menu.show .form-check .form-check-label {
    cursor: pointer;
    width: 100%;
}
.custom-modal1 .custom-form-modal .dropdown .dropdown-menu.show .form-check:hover {
    background: #0092A6 !important;
    color: #fff !important;
}
.table-responsive::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 8px;
}
#sportmanship-modal .modal-body .form-group.form-group1.Involved-from .form-group:nth-child(2) .dropdown.show .dropdown-menu.show .form-check .form-check-label {
    cursor: pointer;
    width: 100%;
}

.table-responsive::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 8px;
}
.table thead tr th {
    margin-bottom: 20px;
}
.universities-list .table tbody tr {
    margin-bottom: 20px;
    padding: 0px 0px 20px 0px;
}
.bookmark-icon-holder {
    display: flex;
    align-items: center;
    gap: 10px;
}
.bookmark-icon-holder h4 {
    margin-bottom: 0px;
}

.tabs-custom-listing-block .tabs-custom-listing-holder .circle-icon-holder h4 {
    margin-bottom: 0px !important;
}
.bookmark-icon-holder button:active {
    background: #45aad6 !important;
    color: #fff !important;
}
.bookmark-icon-holder span{
    color: #45aad6;
    margin-right: 40px;
    cursor: pointer;
}
.bookmark-icon-holder span.date-hours-weeks-style{cursor:auto;}
.our-custom-tab-content .list-two-titles{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #686868;
    padding-bottom: 15px;
    margin-bottom: 20px;
}
.our-custom-tab-content .list-two-titles h3{
    border-bottom: none;
    margin: 0;
    padding: 0;
}
.bookmark-icon-holder span.active{color:#45AAD6;
}
.our-custom-tab-content h3,
.alltabs-list-table thead tr th{
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #000;
    padding-bottom: 15px;
    border-bottom: 1px solid #686868;
    margin-bottom: 20px;
}
.alltabs-list-table thead tr th:last-child{
    text-align: right;
    padding-right: 0px;
}
.playground-tab .alltabs-list-table thead tr th:last-child{text-align: left;}
.tabs-custom-listing-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.tabs-custom-listing-holder:last-child{border-bottom: none;}
.tabs-custom-listing-holder h4 span svg,
.alltabs-list-table h4 span svg{color: #4FB5EF;}
.main-search-block{
    width: auto !important;
    position: absolute;
    left: 50%;
    right: auto;
    transform: translate(0, 0px);
    margin-left: 188px;
}
.date-hours-weeks-style{
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #000;
}
.tabs-empty-data-block{
    height: 36vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.empty-text-holder{
    margin-bottom: 0px;
    border: none;
}
.empty-text-holder h3 {
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom: 0px;
    background: transparent;
    align-items: center;
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    height: 250px;
    color:#000;
}
/*Modal Css*/
.custom-modal1 .modal-body p{
    font-family: 'inter';
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 20px;
    color:#000;
}
.custom-modal1 .modal-content {
    box-shadow: 0px 4px 4px 0px #00000040;
    border: none !important;
}
.custom-modal1 .modal-footer{border-top: none!important;}
.custom-modal1 .modal-footer .btn.btn-primary{
    background: #45AAD6;
    color: #fff;
    border: none;
    outline: none;
    transition: all 0.3s ease-in-out;
    min-width: 100px!important;
    height: 50px!important;
    border-radius: 8px!important;
    font-weight: 700!important;
    font-size: 17px!important;
    line-height: 20px!important;
    margin: 0px;
}
.custom-modal1 .modal-footer .btn.btn-primary:hover{opacity: 0.8;}
.custom-modal1 .modal-content .form-group .custom-form-slect-field{
    box-shadow: 0px 1px 4.01px 0px #00000040;
    height: auto;
    border-radius: 8px;
    position: relative;
}
.custom-modal1 .custom-form-slect-field .dropdown button{
    max-width: none!important;
    width: 100%;
    position: relative;
}
#sportmanship-modal .custom-form-slect-field .dropdown button{
    margin-top: 0px;
    justify-content: flex-start;
}
#sportmanship-modal .custom-form-slect-field .dropdown button:hover{background: transparent!important;}
.custom-modal1 .custom-form-slect-field .dropdown button
.custom-modal1 .custom-form-slect-field .dropdown-toggle::after{
    position: absolute;
    left: auto;
    right: 23px;
    top: 50%;
    margin-top: -6px;
}
.custom-modal1 .custom-form-slect-field .show.dropdown .dropdown-menu.show{
    width: 100%;
    border:1px solid #A4A4A4;
    margin-top: 0px;
}
.custom-modal1 .modal-footer{
    justify-content: flex-start!important;
    padding-bottom: 0!important;
    padding-top: 25px;
}
.custom-modal1 .modal-content{
    max-width: 461px;
    margin: 0px auto 0px;
    padding:54px 37px 40px 37px;
    border-radius: 10px;
}
.question-modal-block .modal-content{
    max-width: 1006px;
    min-width: 1006px;
}
.custom-modal2 .modal-content{
    max-width: 852px;
    margin: 0 auto;
}
.custom-modal1 .form-group:last-child{margin-bottom: 0;}
.form-label{
    margin-bottom: 15px!important;
    color:#000;
    font-weight: 500!important;
}
.custom-modal1 .modal-body{
    padding-bottom: 5px;
    padding-top: 0;
}
.MainCustomRow .side-main-content .select-field-block.d-flex.align-items-center .custom-form-slect-field {
    min-width: 250px;
    width: 25%;
    margin-right: 0px;
}
.MainCustomRow .side-main-content .header-holder .select-field-block .header-custom-from-select-field {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}
.custom-modal2 .form-group1,
.question-modal-block .form-group1{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.question-modal-block .form-group1{
    justify-content: flex-start;
    gap: 0px;
}
.question-modal-block #custom-modal .form-group1{
    margin-bottom: 10px;
}
.question-modal-block .form-group1:last-child .form-group-contain-sbmt-btn-holder{gap:24px;}
.custom-modal2 .form-group1 .form-group-contain,
.question-modal-block .form-group1 .form-group-contain{
    flex-basis: 48%;
}
.question-modal-block .form-group1 .form-group-contain{
    flex-basis: 37.2%;
    margin-right: 38px;
}
.question-modal-block .form-group1 .form-group-contain:last-child{margin-right: 24px;}
.question-modal-block .form-group1 .form-group-contain.form-group-contain-hours-week-pannel{margin-right: 24px;}
.question-modal-block .form-group1 .form-group-contain.form-group-contain-sbmt-btn-holder{flex-basis: 11.4%;}
.custom-modal1.question-modal-block form.custom-form-modal .form-group-contain-sbmt-btn-holder button{
    width: 100%;
    margin-top: 35px!important;
    height: 50px;
    box-shadow: 0px 1px 4.01px 0px #00000040;
}
.form-group-activites-list{
    display: flex;
    flex-direction: column;
    max-height: 20vh;
    max-width: 100%;
    gap: 20px;
}

.question-modal-block #custom-modal .form-group-activites-list{
    margin-top: 5px;
}
.form-group-activites-list-block {
    overflow-y: auto;
    flex: 1; 
    gap: 12px;
    display: flex;
    flex-direction: column;
}
#custom-modal .form-group-activites-list .form-group-activites-list-block .row .col {
    white-space: unset;
}
.form-group-activites-list-block::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
.form-group-activites-list-block::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}
.form-group-activites-list-block::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}
.form-group-activites-list-block ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 48px;
    align-items: center;
}
.form-group-activites-list-block ul li{
    font-size: 16px;
    font-weight: 500;
}
.form-group-activites-list .form-label{
    margin-bottom: 12px!important;
}
.add-another{
    font-family: 'Inter';
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color:#45AAD6;

}
.custom-modal2 .modal-footer .btn.btn-primary{margin-right: 15px;}
/*modal tab css*/
.custom-modal3 .modal-content {
    max-width: 530px;
    margin: 42px auto 0px;
  }
  #view-modal .modal-content {
    max-width: 100%;
    margin: 42px auto 0px;
}
#view-modal .modal-content .Profile-from .Reading-section .form-group input {
    width: 200px;
}
#view-modal .modal-content .Profile-from .form-group {
    width: 48%;
}
#view-modal .modal-content .Education-from .Email-section {
    justify-content: space-between;
    width: 100%;
}
#view-modal .modal-content .Education-from .general-section {
    justify-content: space-between;
    width: 100%;
}
#view-modal .modal-content .Education-from .general-section .form-group {
    width: 48%;
}
#view-modal .Education-from .address-from {
    width: 100% !important;
}
#view-modal .Education-from .description-from {
    width: 100% !important;
}
#view-modal .Education-from .name-from {
    width: 100% !important;
}
.Test-button div {
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 21px !important;
    color: #a4a4a4;
}
.Test-button {
    gap: 15px;
    justify-content: flex-start;
    cursor: pointer;
    margin-bottom: 0px;
}
#view-modal .Profile-from h4 {
    margin-bottom: 0px;
}
.Reading-section .btn.btn-primary:hover {
    opacity: 0.8;
}
.Test-button div:active {
    color: #0092a6;
}
#view-modal .modal-content .Education-from .general-section .custom-form-slect-field #dropdown-basic {
    margin: 0px !important;
}
#view-modal .modal-content .Profile-from .High-School-from {
    width: 100% !important;
}
#view-modal .Education-from .form-group {
    width: 48%;
}
.Reading-section {
    justify-content: space-between;
}
.modal-tab-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.Reading-section .btn.btn-primary {
    background: #45AAD6;
    color: #fff;
    border: none;
    outline: none;
    transition: all 0.3s ease-in-out;
    min-width: 100px !important;
    height: 50px !important;
    border-radius: 8px !important;
    font-weight: 700 !important;
    font-size: 17px !important;
    line-height: 20px !important;
    margin-top: 30px;
}
.current-year-section {
    justify-content: space-between;
    width: 100%;
}

  .modal-tab-block .nav-tabs .nav-link,
  .modal-tab-block .nav-tabs .nav-link.active {
    border: 0px;
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 21px !important;
    color: #a4a4a4;
    padding-top: 0px;
  }
  .modal-tab-block .nav-tabs .nav-link.active {
    color: #0092a6;
  }
  .modal-tab-block .nav-tabs .nav-link:first-child {
    padding-left: 0px;
  }
  .modal-tab-block .nav-tabs {
    border: 0px;
    margin-bottom: 10px;
  }
  .custom-modal3 .modal-body > h4 {
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 21px !important;
    margin-bottom: 20px;
  }
  .custom-modal3 .modal-tab-block h5 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 15px;
  }
  .custom-modal3 .modal-content .form-group .custom-form-slect-field {
    box-shadow: 0px 1px 4.01px 0px #00000040;
  }
  .custom-modal3 .custom-form-slect-field .dropdown button::placeholder {
    color: #a3a3a3 !important;
  }
  .custom-modal3 .form-group .form-control {
    box-shadow: 0px 1px 4.01px 0px #00000040;
    height: auto;
    border-radius: 8px;
    height: 50px;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 19.42px;
    text-align: left;
    color: #a3a3a3;
  }
  .custom-modal3 .form-group .form-control::placeholder {
    color: #a3a3a3;
  }
  .custom-modal3 .form-group .form-control:focus {
    box-shadow: 0px 1px 4.01px 0px #00000040;
    border: none;
  }
  .custom-modal3 .form-group textarea.form-control {
    min-height: 119px;
    resize: none;
  }
  .custom-modal3 .form-number-group .form-control {
    max-width: 90px;
    text-align: center;
    margin-right: 15px;
    display: inline-block;
    vertical-align: middle;
  }
  .custom-modal3 .form-number-group .form-label {
    display: block;
    margin-bottom: 10px !important;
  }
  .form-numb-group-holder {
    display: flex;
    justify-content: space-between;
  }
  .modal-tab-block .form-label {
    color: #000;
    font-weight: 500 !important;
    font-size: 17px;
    margin-bottom: 10px !important;
}
/*Get's Started Screen Styling*/
.gets-started-contain{
    background-image: url('../images/gets-started.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    max-height: 100% !important;
    height: 100vh;
    flex-direction: column;
}
.main-app-wrappers {
    overflow-x: hidden;
    height: 100dvh;
    overflow-y: auto;
}
.gets-login-contain {
    background-image: url('../images/gets-started.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    /* height: calc(100dvh - 0px);
    max-height: 100% !important; */
    position: relative;
}
.gets-login-contain p {
    font-weight: 500;
    line-height: 40px;
    font-size: 29px;
    color: #153a3a;
}
.gets-login-contain::after {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background: rgba(245, 245, 245, 0.8);
}
.gets-started-contain p{
    font-family: 'Inter';
    font-weight: 500;
    font-size: 29px;
    line-height: 40px;
    color:#153A3A;
    margin-bottom: 0;
}
.gets-started-contain{
    display: flex;
    position: relative;
}
.gets-started-contain::after{
    display: block;
    content: '';
    width:100%;
    height: 100%;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background: rgba(245, 245, 245, 0.8);
}
.gets-started-holder{
    position: relative;
    z-index: 1;
}
.gets-started-form-holder h2{
    margin-bottom: 0px!important;
    color: rgba(38, 38, 38, 1) !important;
}
.gets-started-form-holder label{
    font-family: 'Inter';
    font-weight:700!important;
    font-size: 19px;
    line-height: 23px;
    color: rgba(38, 38, 38, 1) !important;
}
.gets-started-form-holder .form-group .form-control{
    max-width: 463px;
    height: 43px;
    background: #fff;
    border: none;
    outline: none;
    border-radius: 7px;
    box-shadow: 0px 0.96px 3.83px 0px #00000040;
    color:#000;
    position: relative;
}
.gets-started-form-holder .form-group .form-control::-ms-input-placeholder { /* Edge 12-18 */
    color: #000;
}
.gets-started-form-holder .form-group .form-control::placeholder{color:#000;}
.gets-started-form-holder .form-group{margin-bottom: 0px;}
.gets-started-form-contain{
    display: flex;
    align-items: center;
    background: #DAECEE;
}
.gets-started-form-holder{
    max-width: 585px;
    margin: 0px auto 0px;
    width: 100%;
}
.gets-started-contain .logo-holder{
    position: relative;
    z-index: 1;
}
.gets-login-contain .logo-holder{
    position: relative;
    z-index: 1;
}
.get-started-info-holder{
    position: relative;
    z-index: 1;
}

.account-anchor{
    font-size: 'Inter';
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
    color: #0092A6;
    text-decoration: none;
    display: block;
}
.gets-started-form-holder .gets-form-btn{margin-bottom: 20px;}
/*Setting Css*/
.setting-txt-holder > h4{
    font-size: 20px!important;
    font-weight: 700!important;
    line-height: 24px!important;
    margin-bottom: 25px;
}
.setting-block .setting-txt-placeholder h3{
    font-size: 18px!important;
    font-weight: 600!important;
    line-height: 24px!important;
    margin-bottom: 0px;
    margin-right: 15px;
}
.setting-txt-placeholder .form-control{
    background: #fff;
    max-width: 117px;
    height: 50px;
    border-radius: 8px;
    box-shadow: 0px 1.0029325485229492px 4.011730194091797px 0px #00000040;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000 !important;
    text-align: center;
}
.setting-txt-placeholder .form-control::-webkit-input-placeholder{ /* Edge 12-18 */
    color: #A4A4A4!important;
}
.placeholder-container.setting-txt-placeholder .form-control{
    max-width: 191px;
    min-width: 117px;
}
.placeholder-container.setting-txt-placeholder .form-control:focus{
    border: none;
    outline: none;
    box-shadow: 0px 1.0029325485229492px 4.011730194091797px 0px #00000040;
}
.setting-txt-placeholder .form-control:focus{
    box-shadow: 0px 1.0029325485229492px 4.011730194091797px 0px #00000040;
    border: none;
}
.setting-txt-placeholder {
    display: flex;
    /* align-items: center; */
    margin-bottom: 30px;
}
.setting-txt-input-from .invalid-feedback {
    font-size: 13px;
    margin-top: 0px;
    font-weight: 500;
}
.setting-txt-holder p{
    margin-bottom: 30px;
}
.setting-listing-holder h5{
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 13px;
    border-bottom: 1px solid #686868;
    margin-bottom: 20px;
}
.setting-listing-holder h4{
    font-size: 17px;
    font-weight: 500!important;
    line-height: 20px;
}
.setting-listing-holder .tabs-custom-listing-holder{
    justify-content: space-between;
}
.setting-txt-placeholder.placeholder-container{margin-bottom: 0px;}
.setting-txt-placeholder.placeholder-container .form-control:first-child{
    max-width: 117px;
    margin-left: 0px;
}
.setting-txt-placeholder.placeholder-container .form-control{margin-left: 16px;}
.setting-listing-holder h4{margin-bottom: 0px;}
.setting-txt-placeholder.placeholder-container .custom-form-slect-field .dropdown button{
    background: #fff;
    max-width: 170px!important;
    min-width: 191px;
    height: 50px!important;
    border-radius: 8px!important;
    box-shadow: 0px 1.0029325485229492px 4.011730194091797px 0px #00000040!important;
    font-family: 'Inter'!important;
    font-weight: 500!important;
    font-size: 16px!important;
    line-height: 19px!important;
    color: #A4A4A4!important;
    text-align: center;
    margin-left: 16px;
    margin-right: 0px!important;
}
.setting-txt-placeholder.placeholder-container .custom-form-slect-field .dropdown-toggle::after{
    position: absolute;
    top: 19px;
    left: auto;
    right: 12px;
    border-color:  #A4A4A4;
}
.setting-txt-placeholder.placeholder-container .custom-form-slect-field:nth-last-child(2) {
    margin-right: 0px;
}
.require-asterisk { 
    color: #b5b5b5;
    margin-left: 15px;
}
.require-asterisk-active{
    color: #0092A6;
}
.green-screen{
    background: #daecee;
}
.leadership-block-holder .logo-holder{margin-bottom: 25px;}
.leadership-block{
    padding-top: 88px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    overflow-x: hidden;
    height: calc(100vh - 80px);
}
.grey-box{
    width: 259px;
    height: 69px;
    background: #D9D9D9;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}
.leadership-txt-contain h2{
    font-weight: 600!important;
    font-size: 33px!important;
    line-height: 49px!important;
    font-family: "Poppins", sans-serif!important;
}
.leadership-txt-contain h3{
    font-weight: 500!important;
    font-size: 20px!important;
    line-height: 24px!important;
    font-family: "Inter"!important;
    margin-bottom: 20px;
}
.next-line-holder{
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
}
.next-line-holder span{
    width: 50px;
    height: 6px;
    background: #C7E0E1;
    margin-right: 10px;
}
.next-line-holder span.active{background: #fff;}
.skip-holder h4{
    font-family: 'Inter'!important;
    font-weight: 500!important;
    font-size: 17px!important;
    line-height: 24px!important;
    user-select: none;
}
#join-modal .modal-header .btn-close:focus {
    outline: none !important;
    box-shadow: none !important;
}
.dashboard-name-section ul {
    height: 47px;
    display: flex;
    align-items: center;
}
.skip-holder strong{
    font-family: Poppins !important;
    font-weight: 700!important;
    font-size: 16px!important;
    line-height: 24px!important;
    color: #0092A6;
    margin-bottom: 24px;
    display: block;
}
.course-slection-block{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-bottom: 20px;
}
.course-section{
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #0092A6;
    border-radius: 7px;
    height: 85px;
    padding: 10px;
    font-weight: 500;
}
.icon-rounded {
    background-color: #fff;
    color: #212529 !important;
}
.pricing-info-block .course-slection-block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}
.course-section.active{
    background: #0092A6;
    color: #fff !important;
}
.competitive-box{
    border-radius: 7px;
    width: 150px;
    height: 130px;
    border: 1px solid #0092A6;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 27px;
    cursor: pointer;
}
.competitive-box.active_tab {
    background-color: #0092A6;
}
.competitive-box.active_tab span {
    color: #ffffff;
}
.competitive-box span{
    color: #0092A6;
    font-family: "Poppins", sans-serif!important;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
}
.competitive-boxes{
    display: flex;
    justify-content: space-between;
    gap: 13px
}
.leadership-block {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    text-align: center;
}
/*Footer Css*/
.footer-block{
    background: #0092A6;
    padding: 15px;
}
.footer-block .custm-back-btn.btn.btn-primary{padding-right: 45px;}
.next-page-btn-holder{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding-right: 85px;
}
/*Landing Page Css*/
.nav-wrapper{
    margin-bottom: 48px;
    padding-top: 30px;
}
.nav-wrapper .nav-item:nth-child(3){
    margin-right: 30px;
}
.nav-wrapper .nav-item:last-child{margin-right: 0px;}
.nav-wrapper .nav-item .nav-link{
    font-weight: 500;
    font-size: 22px;
    line-height: 37px;
    color:#153A3A;
    background: transparent;
    padding: 15px 20px !important;
    border-radius: 0px;
}
.nav-wrapper .nav-item .nav-link:hover{
    color: #0192A6;
    border: none;
    outline: none;
    margin-top: 0px;
}
.nav-wrapper .nav-item .btn.btn-primary {
    margin: 0px;
    background: #FFD660 !important;
    padding: 10px 15px;
    width: auto;
    height: 55px;
    text-align: center;
    font-weight: 700;
    font-size: 21px;
    line-height: 37px;
    color: #153A3A;
    border: none;
    outline: none;
    border-radius: 0px;
    cursor: pointer;
    min-width: 190px;
}
.nav-wrapper .navbar-brand > img{width: 100%;}
.dream-info-holder p{
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
    color: #0192A6;
    margin-bottom: 30px;
}
.landingpage-wrapper .dream-info-block .dream-info-holder h1 {
    font-size: 55px!important;
    line-height: 65px!important;
    font-weight: 700;
    font-family: 'Inter';
    color: #153A3A;
    margin-bottom: 16px;
    max-width: 596px;
}
.btn.btn-primary.landing-custom-btn {
    background: #FFD660;
    width: auto;
    min-width: 190px;
    height: 55px;
    text-align: center;
    font-weight: 700;
    font-size: 21px;
    line-height: 37px;
    color: #153A3A;
    border: none;
    outline: none;
    border-radius: 0px;
    cursor: pointer;
}
.btn.btn-primary.landing-custom-btn:hover{
    background: #FFE28F;
}
.landing-page-bg-wrapper{
    /* background-image: linear-gradient(270deg, rgba(255, 255, 255, 0.38) 0%, #FFFFFF 75.49%),url('../images/university-img-min.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center; */
    min-height: 980px;
    position: relative;
    height: 100%;
}
.landing-page-bg-wrapper::before{
    content:"";
    position: absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
    z-index:1;
    background:linear-gradient(270deg, rgba(255, 255, 255, 0.38) 0%, #FFFFFF 83%);
}
.bg-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
}
.video-content-wrapper {
    position: relative;
    z-index: 1;
}
.dream-info-block {
    position: relative;
    z-index: 1;
} 
.green-rect-curve-area-holder {
    position: relative;
    z-index: 1;
}
.laptop-img-holder{position: relative;}
.laptop-img-holder img {
    /* width: 50%;
    height: 50%;
    object-fit: contain; */
    position: absolute;
    right: auto;
    left: 0;
    top: 0;
}
.green-rect-curve-area-holder{
    background-image: url('../images/round-green-rect.webp');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: left top;
    min-height: 290px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 30px;
    position: absolute;
    top: 691px;
}
.test-scenarios-holder button.btn.btn-primary.landing-custom-btn {
    width: 200px;
}
.dollar-list-contain button.btn.btn-primary.landing-custom-btn {
    width: 200px;
}
.foot-submit-holder form button {
    min-width: 150px;
    height: 50px !important;
}
.green-rect-curve-area p {
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    font-family: 'Inter';
    color: #fff;
    text-align: center;
    max-width: 670px;
}
.green-rect-curve-area h2{
    color: #fff;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 0px;
}
.green-rect-curve-area {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}
.landing-page-info-block{
    margin-top: 80px!important;
    margin-bottom: 80px!important;
    align-items: center!important;
}
.landingpage-info-holder p,
.landingpage-info-holder ul li {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    color: #153A3A;
}
.landingpage-info-holder ul{
    margin-bottom: 20px;
    position: relative;
}
.landingpage-info-holder ul li{
    position: relative;
    padding-left: 25px;
}
.landingpage-info-holder ul li::after{
    position: absolute;
    content:'';
    width: 6px;
    height: 6px;
    background: #153A3A;
    left: 3px;
    top: 16px;
    border-radius: 50%;
}
/* .progress-img-block.align-items-center .landingpage-info-holder p {
    max-width: 480px;
} */
.landingpage-info-holder p{
    margin-bottom: 20px;
    /* max-width: 449px; */
}
.landing-page-info-last-block .landingpage-info-holder p{max-width: none;}
.landing-info-img-holder > img{
    width:100%;
    object-fit: cover;
}
.landingpage-info-holder h2{
    font-family: 'Inter';
    font-weight: 700;
    font-size: 48px;
    line-height: 54px;
    color: #153A3A;
    margin-bottom: 20px;
}
.test-scenarios-holder ol{margin-bottom: 50px;}
.chart-score-block{
    padding: 10px 15px;
    background: #038294;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    max-width: 600px!important;
    justify-content: center;
    position: absolute;
    top: 20px;
    left: auto;
    right: -12px;
}
.chart-score-holder{
    margin-right: 15px;
    text-align: center;
}
.chart-score-holder:last-child{margin-right: 0px;}
.chart-score-holder span{
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: #fff;
    margin-bottom: 5px;
    display: block;
}
.chart-score-block .chart-score-holder h4{
    font-family: 'Inter';
    font-size: 21px!important;
    font-weight: 500!important;
    line-height: 25px!important;
    color: #fff!important;
    margin-bottom: 0!important;
}
.bar-chart-img-holder {
    position: absolute;
    top: auto;
    right: -140px;
    left: auto;
    margin-right: 0px;
    margin-top: 18px;
    bottom: -18px;
}
/* .progress-img-block .landingpage-info-holder p{max-width: 375px;} */
.progress-img-block{margin-bottom: 80px;}
.bar-chart-img-holder > img{width: 70%;}
.progress-img-block-holder > img{
    width: 100%;
    object-fit: cover;
}
.landingpage-info-holder1{
	padding-left: 155px;
}
.landingpage-info-holder2{
	padding-left: 0px;
}
.test-scenarios-block{
    background-image: url('../images/testimonal.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left top;
    height: auto;
    position: relative;
    padding: 50px 15px 50px;
    align-items: center;
}
.test-scenarios-block::after{
    position: absolute;
    content: '';
    background: rgba(54, 53, 53, 0.88);
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
}
.test-scenarios-holder{
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    text-align: center;
    color: #fff !important;
    position: relative;
    z-index: 1;
    margin: 0 auto;
    padding: 0 60px;
}
.test-scenarios-holder p{margin-bottom: 20px;}
.test-scenarios-holder p{
    font-weight: 500;
    line-height: 33px;
    font-family: 'Inter';
    font-size: 22px;
}
.test-scenarios-holder h2{
    font-family: 'Inter';
    font-size: 48px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    margin-bottom: 20px;
    color: #FFFFFF;
}
.pricing-info-block{
    max-width: 660px;
    margin: 0 auto;
    text-align: center;
}
.pricing-info-block > p{
    font-family: 'Inter';
    font-size: 24px;
    font-weight: 500;
    line-height: 33px;
    margin-bottom: 20px;
}
.pricing-info-block p {
    color: #153A3A !important;
}
.pricing-info-block h2{
    font-family: 'Inter';
    font-size: 40px!important;
    font-weight: 700!important;
    line-height: 45px!important;
    text-align: center;
    margin-bottom: 10px;
    color: #153A3A;
}
.pricing-info-block p{margin-bottom: 20px;}
.pricing-info-block .course-slection-block{
    justify-content: center;
    margin-bottom: 30px;
}
.pricing-info-block .course-section {
    min-width: 150px;
    border: 1px solid #0092A6;
    border-radius: 7px;
    padding: 15px 15px;
}
.pricing-info-block .course-section.active{
    margin-left: 0px;
}
#pricing-modal .pricing-info-block .nav-tabs .nav-link{min-width: 110px;}
.pricing-info-block .nav-tabs .nav-link,
.pricing-info-block .nav-tabs .nav-link:focus,
.pricing-info-block .nav-tabs .nav-link:hover{
    min-width: 180px;
    border: 1px solid #0092A6;
    font-family: 'Inter';
    font-size: 21px;
    font-weight: 600;
    line-height: 23px;
    text-align: center;
}
#pricing-modal .pricing-info-block .nav-tabs .nav-link:hover{background: transparent;}
#pricing-modal .pricing-info-block .nav-tabs .nav-link.active span:hover{background: transparent;}
.pricing-info-block .nav-tabs .nav-link span{color:#0092A6;}
#pricing-modal .pricing-info-block .nav-tabs .nav-link span{
    font-size: 18px;
    line-height: 21px;
}
.pricing-info-block .nav-tabs .nav-link.active,
.pricing-info-block .nav-tabs .nav-link.active:focus{
    background: #0092A6;
    /* border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; */
    margin-right: -1px;
    border: 1px solid transparent;
}
.pricing-info-block .nav-tabs .nav-link.active span{
    color: #fff;
    font-weight: 600;
}
.pricing-info-block ul.nav-tabs{
    border-bottom: 0px;
    justify-content: center;
}
.pricing-info-block #controlled-tab-example1-tab-profile{
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.pricing-info-block #controlled-tab-example1-tab-home{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}
.payment-list-box h3{
    font-family: 'Inter';
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color:#153A3A;
    margin-bottom: 20px;

}
#pricing-modal .payment-list-box h3{margin-bottom: 11px;}
.payment-list-box ul{
    margin-bottom: 30px;
    min-height: 260px;
}
.payment-list-box ul li{
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    margin-bottom: 12px;
}
.payment-list-box strong{
    font-family: 'Inter';
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    display: block;
    margin-bottom: 30px;
}
.payment-list-box .btn.btn-primary.landing-custom-btn{
    font-weight: 700;
    margin-bottom: 30px;
    font-family: 'Inter';
}
.pricing-info-block .course-slection-block .course-section {
    font-weight: 600;
    line-height: 27px;
    color: #0092A6;
}
.payment-list-box{
    background: #fff;
    border:1px solid #eaeaea;
    box-shadow: 0px 4px 4px 0px #00000040;
    padding-top: 30px;
    max-width: 330px;
    min-width: 290px;

    margin-top: 45px;
    padding-left: 35px;
    padding-right: 35px;
    border-radius: 5px;
}
.payment-list-box{
    min-height: 500px;
    position: relative;
}
.pricing-block{padding-bottom: 100px;}
.dollar-list-contain{
    position: static;
}
.payment-tab-holder{
    display: flex;
    justify-content: center;
}
.payment-tab-content{margin-right: 25px;}
.landing-page-footer .footer-top {
    background: #346c70;
    padding: 60px 0px;
    font-size: 18px;
    line-height: 24px;
    font-family: "Inter";
    color: #fff;
    font-weight: 300;
  }
  .landing-page-footer .logo-holder {
    margin-bottom: 10px;
  }
  .footer-top .list-holder {
    margin-top: 15px;
  }
  .footer-top .list-holder ul {
    margin-bottom: 0px;
    width: 50%;
    margin: 0 auto;
  }
  .footer-top .list-holder li {
    margin-bottom: 10px;
  }
  .footer-top .list-holder a {
    font-family: "Inter";
    color: #fff;
    font-weight: 400;
    text-decoration: none;
    font-size: 21px;
    line-height: 34px;
  }
  .foot-submit-holder h3 {
    font-size: 21px;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 0px;
  }
  .foot-submit-holder .form-control {
    background-color: #FFFFFF;
    border-radius: 0px;
    min-height: 40px;
    max-width: 280px;
    margin-bottom: 0px;
    border: none;
    outline: none;
    box-shadow: none;
  }
  .foot-submit-holder form button.btn.btn-primary.landing-custom-btn.submit-btn {
    font-weight: 600 !important;
    font-size: 20px;
    line-height: 29px !important;
    font-family: 'Inter';
}
  .foot-submit-holder .form-control:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
  .btn.btn-primary.landing-custom-btn.submit-btn {
    min-width: 150px;
    height: 40px;
    font-size: 18px;
  }
  .foot-submit-holder {
    margin-top: 10px;
  }
  .landing-page-footer .footer-bottom {
    background: #173d3e;
    color: #5d9293;
    font-size: 18px;
    line-height: 24px;
    font-family: "Inter";
    font-weight: 400;
    text-align: center;
    padding: 25px 15px;
  }
  .landing-page-footer .logo-holder a img {
    width: 80%;
    height: 100px;
    object-fit: contain;
}
  .landing-page-footer .footer-bottom p {
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 20px;
    font-family: 'Inter';
  }
  .landing-page-footer .footer-top p {
    margin-bottom: 0px;
    font-weight: 400;
    line-height: 29px;
    max-width: 370px;
    font-size: 21px;
  }
  .landingpage-wrapper{overflow-x: hidden;}
  /*Dashboard Page Styling*/
  .custom-line-chart-block .custom-form-slect-field,
  .custom-sidebar-chart .custom-form-slect-field{
    box-shadow: 0px 1px 4.01px 0px #00000040;
    border-radius: 8px;
    max-width: 340px;
    margin-bottom: 15px;
  }
  .custom-line-chart-block.custom-line-chart-block h4{
    margin-bottom: 25px;
  }
  .custom-line-chart-block .custom-form-slect-field .dropdown-toggle::after,
  .custom-sidebar-chart .custom-form-slect-field .dropdown-toggle::after{
    vertical-align: 0.2em;
    position: absolute;
    left: auto;
    right: 18px;
}
  .custom-sidebar-chart .custom-form-slect-field .dropdown-toggle::after{
    margin-left: 12.3em;
    position: absolute;
    left: auto;
    right: 19px;
  }
  .custom-line-chart-block .custom-form-slect-field .show.dropdown .dropdown-menu.show::after{
    width: 100%;
  }
  .tabs-custom-listing-holder .circle-icon-holder {
    width: 50%;
}
/* .custom-vertical-scroll-tbl > div{margin-right: 0px!important;} */
.side-bar.custom-sidebar-chart .circle-icon-holder{width: auto;}
.side-bar.custom-sidebar-chart .our-custom-tab-content h4{margin-bottom: 0px;}
.tabs-custom-listing-holder .bookmark-group {
    width: 30%;
}
.tab-list-head {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #686868;
    margin-bottom: 20px;
}
.side-main-content .our-custom-tab-content .tabs-custom-listing-block {
    overflow-y: auto;
    height: calc(40vh - 15px);
    padding-right: 8px;
}
.tab-list-head h3 {
    border: none !important;
    margin-bottom: 0px !important;
}
.tab-list-head h3:first-child {
    width: 30%;
}
.tab-list-head h3:nth-child(2) {
    width: 71%;
    text-align: center;
}
.our-custom-tab-content .list-two-titles h3:first-child {
    width: 20%;
}
.our-custom-tab-content .list-two-titles h3:nth-child(2) {
    width: 38%;
    text-align: center;
}
.tabs-custom-listing-block.selected-tab .tabs-custom-listing-holder .circle-icon-holder {
    width: 39%;
}
.tabs-custom-listing-block.selected-tab .tabs-custom-listing-holder .bookmark-group {
    width: 30%;
}
.our-custom-tab-content .list-two-titles h3:nth-child(3) {
    width: 32%;
}
.our-custom-tab-content .list-two-titles h3:nth-child(4) {
    width: 10%;
}
.tab-list-head h3:nth-child(3) {
    width: 17%;
}
  .custom-sidebar-chart .custom-form-slect-field .show.dropdown .dropdown-menu.show{width: 100%;}
  .line-chart-main-row{
    margin-left: 15px!important;
    margin-right: 15px!important;
  }
  .our-custom-tab-content > h4 > span.tier-span{
    font-size: 17px;
    font-weight: 500;
    line-height: 21px;
    color: #47A0C7;
    margin-left: 0px;
  }
  .line-chart-main-container .Line_chart .custom-form-slect-field #dropdown-basic:focus-visible {
    box-shadow: none !important;
  }
  .popover.chart-popover{
    padding: 16px 30px!important;
    max-width: 315px!important;
    min-width: 250px;
    left: 90px!important;
    -webkit-filter: drop-shadow(1px 1px 1px rgba(0,0,0,.5));
    filter: drop-shadow(1px 1px 1px rgba(0,0,0,.5));
    margin-bottom: 12px;
  }
  .popover.chart-popover .popover-arrow::after,
  .popover.chart-popover .popover-arrow::before{
      right: 85px!important;
  }
.custom-modal1.custom-modal2.custom-modal3.modal.show .modal-dialog.modal-lg .modal-content .modal-tab-block textarea.form-control::placeholder {
    color: #a3a3a3 !important;
}
.custom-modal1.custom-modal2.custom-modal3.modal.show .modal-dialog.modal-lg .modal-content .modal-tab-block .form-group input::placeholder {
    color: #a3a3a3 !important; 
}
.custom-modal1.custom-modal2.custom-modal3.modal.show .modal-dialog.modal-lg .modal-content .modal-tab-block textarea.form-control {
    padding: 15px !important;
}
.select-field-block .head-btn-container button.adminBtn.btn.btn-success {
    height: 50px;
    min-width: 200px;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    border-radius: 8px !important;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background: #ffd660 !important;
    color: #000 !important;
    border: none !important;
   box-shadow: none !important;
} 
.main-search-blocks.journey-search .search-input-holder input {
    color: #000000;
}
.side-bar.custom-sidebar-chart .bookmark-icon-holder span{margin-right: 0px;}
.select-field-block .head-btn-container button.adminBtn.btn.btn-success:hover {
background: #ffe28f !important;
border: none !important;
box-shadow: none !important;
}
  .side-bar .head h3{margin-bottom: 20px;}
  .custom-modal1 .form-group .form-control{
    height: 50px !important;
    border-radius: 8px !important;
    color: #000000 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    font-weight: 500 !important;
    font-family: 'Inter' !important;
    border: none !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background: #fff;
    text-align: left;
    position: relative;
    box-shadow: 0px 1px 4.01px 0px #00000040;
    text-overflow: ellipsis;
  }
  .custom-modal1 .form-group .form-control::placeholder{color:#000;}
  /*Multiselect Styling*/
  .dropdown-toggle.custom-multiselect-dropdwn,
  .dropdown-toggle.custom-multiselect-dropdwn:hover,
  .dropdown-toggle.custom-multiselect-dropdwn:focus{
    height: 50px !important;
    border-radius: 8px !important;
    color: #000 !important;
    font-weight: 500 !important;
    font-family: 'Inter' !important;
    border: none !important;
    padding-left: 23px !important;
    padding-right: 23px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background: #ffffff;
    text-align: left;
    position: relative;
    box-shadow: 0px 1px 4.01px 0px #00000040;
  }
  .dropdown-toggle.custom-multiselect-dropdwn::after{
    position: absolute;
    left: auto;
    right: 23px;
    top: 50%;
    margin-top: -6px;
    content: "";
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .custom-modal1 .form-check-input:checked {
    background-color: #45AAD6;
    border-color: #45AAD6;
    box-shadow: none;
    outline: none;
}
.custom-modal1 .form-check-input:focus {
    border-color: #45AAD6;
    outline: 0;
    box-shadow: none;
}
/*Slick Slider Styling*/
.chart-score-slider{
    width: 45%;
    padding: 7px 15px;
    background: #038294;
    border-radius: 5px;
    margin-right: 18px;
}
.chart-score-slider .chart-score-holder{
    color: #fff;
}
.chart-score-slider .chart-score-holder span{
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #fff;
    margin-bottom: 1px;
    display: block;
}
.chart-score-slider .chart-score-holder h4{
    font-family: 'Inter';
    font-size: 16px!important;
    font-weight: 500!important;
    line-height: 18px!important;
    color: #fff!important;
    margin-bottom: 0!important;
}
.chart-score-slider .slick-dots li.slick-active button:before,
.chart-score-slider .slick-dots li button:before{
    opacity: 1;
    color: #fff;
    font-size: 10px;
}
.chart-score-slider .slick-dots li button:before{
    color: #3D4445;
}
.chart-score-slider .slick-dots{
    bottom: -27px;
}
.chart-score-slider .slick-dots li{
    margin: 0 1px;
}
.main-search-blocks .search-input-holder input {
    color: #000000;
}
.main-app-wrapper .navbar a.navbar-brand img {
    width: 65%;
    height: 80%;
    object-fit: contain;
}
.list-group-item+.list-group-item.active {
    margin: 0px !important;
}
.username p {
    user-select: none;
}
.profile-dropdown-2 ul li {
    user-select: none;
}
#userDropdown {
    gap: 10px;
    border: 0px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(118, 137, 148, 0.1);
    font-family: Poppins;
}
.card-view .card-body .card-content .text-center {
    text-align: start !important;
}
.navbar-expand-lg .navbar-nav {
    flex-direction: row;
    display: flex;
    align-items: center;
    gap: 10px;
}
.admin_logout {
    border-bottom: none !important;
}
.was-validated .form-control:valid {
    border: none !important;
    outline: none !important;
}
#custommodal2 .was-validated .form-control:valid:focus {
    box-shadow: 0px 1px 4.01px 0px #00000040 !important;
}
.username {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.gets-started-form-holder .custom-form-slect-field{margin-bottom: 15px;}
.gets-started-form-holder .custom-form-slect-field .dropdown button{
    width: 100%!important;
    height: 43px!important;
    max-width: 465px;
    box-shadow: 0px 0.9563678503036499px 3.8254714012145996px 0px #00000040!important;
    border-radius: 7px!important;
}
.gets-started-holder.gets-started-form-holder .custom-form-slect-field.get-started-form .dropdown-menu.show {
    max-width: 465px;
}
.header-holder .select-field-block {
    display: flex !important;
    justify-content: space-between;
    width: 94.5%;
}
.gets-started-form-holder .custom-form-slect-field .dropdown-toggle::after{top: 16px;}
.was-validated .form-control:invalid:focus{
    box-shadow: 0px 0.9563678503036499px 3.8254714012145996px 0px #00000040;
    outline: none !important;
}
.custom-form-modal .form-control:valid {
    box-shadow: 0px 1px 4.01px 0px #00000040 !important;
}
#sportmanship-modal .form-group.form-group1.Involved-from .form-group .custom-form-slect-field #Accomplishment {
    text-align: start;
    justify-content: flex-start;
}
#sportmanship-modal .form-group.form-group1.Involved-from .form-group .custom-form-slect-field #Accomplishment:hover {
    background-color: transparent !important;
}
#custommodal2 .modal-tab-block form .tab-content .value-modal-coustom2 .form-numb-group-holder {
    width: 100%;
    display: flex;
   justify-content: space-between;
}
#custommodal2 .modal-tab-block form .tab-content .value-modal-coustom2 .form-numb-group-holder .form-number-group {
    width: 50%;
}
.tabs-custom-listing-holder-button {
    display: flex;
    justify-content: flex-end;
}
.setting-listing-holder.scrolled .text-center.empty-text-holder h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #000;
}
.ideal-header{flex:2;}
.tabs-custom-listing-block {
    cursor: pointer;
}

.tabs-custom-listing-block::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
}
.tabs-custom-listing-block::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
  .setting-txt-input-from {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.landing-page-footer .footer-top .foot-submit-holder form.was-validated .invalid-feedback {
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    margin: 0px;
    max-width: fit-content;
}
.landing-page-footer .footer-top .foot-submit-holder {
    display: flex;
    gap: 12px;
    flex-direction: column;
    align-items: flex-start;
}
.users-list-table{vertical-align: middle;}
.users-list-table td .btn.btn-primary{
    background:#45AAD6;
    border: 0px;
}
.users-list-table td .btn.btn-primary:hover{opacity: 0.8;}
.users-list-table tr:last-child td{border-bottom: none;}
.users-list-table tr th:last-child{text-align: right;}
.users-list-table tr td:last-child{
    text-align: right;
}
#custom-modal button.btn.btn-primary:focus, #custom-modal button.btn.btn-primary:active {
    color: #fff;
   background: #45AAD6;
}
#custom-modal button.btn.btn-primary:disabled {
    color: #fff;
    background: #45AAD6; 
}
#custom-modal button.btn.btn-primary:focus-visible {
    color: #fff !important;
    background: #45AAD6 !important;
}
#view-modal .modal-footer{padding-left: 0px;}
#sportmanship-modal .modal-footer{padding-left: 0px;}
.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
    outline: none !important;
}
.was-validated .form-control:valid{
    box-shadow: 0px 1px 4.01px 0px #00000040!important;
}
.was-validated .form-control:valid:focus,
.was-validated .form-control:valid:focus-within{
    box-shadow: 0px 1px 4.01px 0px #00000040!important;
}
.setting-txt-input-from{position: relative;}
.setting-txt-input-from .invalid-feedback{
    position: absolute;
    bottom: -21px;
    top: auto;
    left: 2px;
}
.Journey-tabs-table .alltabs-list-table tbody tr td{
    border-bottom: none;
    padding-top: 10px;
    padding-bottom: 10px;
}
.Journey-tabs-table .alltabs-list-table tbody tr{border-bottom: 1px solid #e2e2e2;}
.Journey-tabs-table .alltabs-list-table tbody tr:last-child{border-bottom: 0px;}
.universities-tab .tab-content .table-responsive.all-tabs-table-holder {
    max-height: none;
  }
  .universities-tab .our-custom-tab-content .table-responsive.all-tabs-table-holder{max-height: none;}
  .playground-tab .tab-content .table-responsive.all-tabs-table-holder{
    max-height: none;
  }
  .journey-tab .tab-content .table-responsive.all-tabs-table-holder{
    max-height: none;
  }
  .playground-tab .table-responsive.all-tabs-table-holder table.alltabs-list-table.table,
  .universities-tab .table-responsive.all-tabs-table-holder table.alltabs-list-table.table,
  .universities-tab .our-custom-tab-content .table-responsive.all-tabs-table-holder table.alltabs-list-table.table,
  .journey-tab .table-responsive.all-tabs-table-holder table.alltabs-list-table.table,
  .MainCustomRow.row .side-main-content .user-tble-contain.container .user-list-tble-holder table.users-list-table.table{
    display: flex;
    flex-flow: column;
    width: 100%;
    margin-bottom: 0px;
  }
  .playground-tab .table-responsive.all-tabs-table-holder table.alltabs-list-table.table thead,
  .universities-tab .table-responsive.all-tabs-table-holder table.alltabs-list-table.table thead,
  .universities-tab .our-custom-tab-content .table-responsive.all-tabs-table-holder table.alltabs-list-table.table thead,
  .journey-tab .table-responsive.all-tabs-table-holder table.alltabs-list-table.table thead,
  .MainCustomRow.row .side-main-content .user-tble-contain.container .user-list-tble-holder table.users-list-table.table thead{
    flex: 0 0 auto;
  }
  .playground-tab .table-responsive.all-tabs-table-holder table.alltabs-list-table.table thead tr,
  .universities-tab .table-responsive.all-tabs-table-holder table.alltabs-list-table.table thead tr,
  .universities-tab .our-custom-tab-content .table-responsive.all-tabs-table-holder table.alltabs-list-table.table thead tr,
  .journey-tab .table-responsive.all-tabs-table-holder table.alltabs-list-table.table thead tr,
  .MainCustomRow.row .side-main-content .user-tble-contain.container .user-list-tble-holder table.users-list-table.table thead tr{
    width: 100%;
    display: table;
    table-layout: fixed;
  }
  .playground-tab .table-responsive.all-tabs-table-holder table.alltabs-list-table.table tbody,
  .universities-tab .table-responsive.all-tabs-table-holder table.alltabs-list-table.table tbody,
  .universities-tab .our-custom-tab-content .table-responsive.all-tabs-table-holder table.alltabs-list-table.table tbody,
  .journey-tab .table-responsive.all-tabs-table-holder table.alltabs-list-table.table tbody,
  .MainCustomRow.row .side-main-content .user-tble-contain.container .user-list-tble-holder table.users-list-table.table tbody{
    display: block;
    overflow-x: hidden;
    padding-right: 0px;
    border-bottom-width: 0px;
  }
  .playground-tab .table-responsive.all-tabs-table-holder table.alltabs-list-table.table tbody tr,
  .universities-tab .table-responsive.all-tabs-table-holder table.alltabs-list-table.table tbody tr,
  .universities-tab .our-custom-tab-content .table-responsive.all-tabs-table-holder table.alltabs-list-table.table tbody tr,
  .journey-tab .table-responsive.all-tabs-table-holder table.alltabs-list-table.table tbody tr,
  .MainCustomRow.row .side-main-content .user-tble-contain.container .user-list-tble-holder table.users-list-table.table tbody tr{
    width: 100%;
    display: table;
    table-layout: fixed;
  }
  .universities-tab .table-responsive.all-tabs-table-holder{max-height: none!important;}
  .MainCustomRow.row .side-main-content .user-tble-contain.container .user-list-tble-holder table.users-list-table.table tbody tr td{
    word-wrap: break-word;
    white-space: normal;
    font-size: 15px;
  }
  .MainCustomRow.row .side-main-content .user-tble-contain.container .user-list-tble-holder table.users-list-table.table thead tr th:nth-child(3),
  .MainCustomRow.row .side-main-content .user-tble-contain.container .user-list-tble-holder table.users-list-table.table thead tr th:nth-child(4){
    padding-left: 0px;
  }
  .line-chart-rightside .Bar_chart{margin-top: 0px;}
  .playground-tab .table>:not(caption)>*>* {
    padding: 0px;
    background: transparent;
  }
  .playground-tab .table-responsive{padding-right: 0px;}
  #custom-modal form.custom-form-modal button.question-multiple-btn{margin-top: 0px;}
  .playground-tab .table.alltabs-list-table.table.table thead tr{border-bottom: 1px solid #686868;}
  .universities-tab .alltabs-list-table.table thead tr{border-bottom: 1px solid #686868;}
  .playground-tab .table.alltabs-list-table.table.table thead tr td{border: none;}
  .setting-listing-holder .table>:not(caption)>*>*{
    padding: 0px;
    border-bottom: none;
}
.setting-listing-holder table tbody tr td .tabs-custom-listing-holder{
    border-bottom: 1px solid #e2e2e2;
}
.setting-listing-holder .tabs-custom-listing-holder-button{margin-right: 28px;}
#custom-modal .custom-form-modal .dropdown .dropdown-menu.show {
    position: absolute;
    top: 33px;
    padding: 0px !important;
}
.dashboard-dropdown-menu{
    max-height: 200px;
    overflow-y: auto;
}

.dashboard-dropdown-menu::-webkit-scrollbar{
    width: 8px;
}
.dashboard-dropdown-menu::-webkit-scrollbar-thumb{
    background: #888;
    border-radius: 8px;
}
.dashboard-dropdown-menu::-webkit-scrollbar-track{
    background: #f1f1f1;
    border-radius: 8px;
}


/* src/PaymentProcessing.css */
.payment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #f1f1f1;
    color: #000;
    font-family: 'Arial', sans-serif;
    text-align: center;
  }
  
  .payment-text {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  
  .loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .loader {
    border: 8px solid rgba(0, 0, 0, 0.3);
    border-top: 8px solid rgba(0, 0, 0, 0);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loader-text {
    margin-top: 1rem;
    font-size: 1.2rem;
  }
  
  .success-text {
    font-size: 1.5rem;
    color: #28a745;
  }
  /* .user-line-chart-main-roww{
    overflow-y: auto;
    height: 70vh;
  }
  .user-line-chart-main-roww::-webkit-scrollbar {
    width: 8px;
  }
  .user-line-chart-main-roww::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }
  .user-line-chart-main-roww::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
  } */
  .StripeElement{margin-bottom: 15px;}
  #join-modal button[type="submit"]{
    background: #45AAD6;
    color: #fff;
    border: none;
    outline: none;
    transition: all 0.3s ease-in-out;
    min-width: 100px;
    height: 45px;
    border-radius: 8px;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    margin: 0px;
  }
  #join-modal .was-validated .form-control:valid:focus{
    box-shadow: 0px 1px 4.01px 0px #00000040!important;
  }
  .universities-tab .our-custom-tab-content > p{
    padding-right: 0px;
    font-size: 17px;
    line-height: 1.4em;
  }
  .universities-tab .alltabs-list-table.table thead tr th:nth-child(2){
    position: relative;
    left: -3px;
  }
  .universities-tab .bookmark-group h4{font-size: 17px!important;}
  .line-chart-rightside .tabs-custom-listing-holder{
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
.universities-responsive::-webkit-scrollbar {
    width: 8px;
}
.universities-responsive::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
}
.universities-responsive::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
}
.Journey-responsive::-webkit-scrollbar {
    width: 8px;
}
.Journey-responsive::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
}
.Journey-responsive::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
}
.user-responsive::-webkit-scrollbar {
    width: 8px;
}
.user-responsive::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
}
.user-responsive::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
} 
.universities-responsive tbody tr:last-child {
    border: none !important;
}
.user-responsive tbody tr:last-child {
    border: none;
}
.user-responsive tbody tr td {
    padding: .5rem .5rem;
}
.universities-responsive tbody tr {
    border-bottom: 1px solid #e2e2e2;
}
.user-responsive tbody tr {
    border-bottom: 1px solid #e2e2e2; 
}
 .main-app-wrapper::-webkit-scrollbar {
    width: 8px;
}
.main-app-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
}
.main-app-wrapper::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
}  
#pricing-modal .modal-content .modal-body .custom-form-modal .tab-content button:hover {
    background: #FFE28F !important;
}
.playground-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.playground-section .bookmark-icon-holder {
    padding-right: 0px !important;
}
.journey-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.journey-section .bookmark-icon-holder {
    padding-right: 0px !important;
}
#join-modal .modal-header h2 {
    margin-bottom: 0px !important;
}
.custom-form-slect-field .dropdown-item {
    height: 50px;
    line-height: 28px;
}
#sportmanship-modal .modal-body .dropdown .dropdown-menu.show .form-check {
    height: 50px;
    line-height: 28px;
 }
 .main-app-wrapper .Bar_chart.custom-line-chart-block div {
    width: 100% !important;
}
.main-app-wrapper .Bar_chart.custom-line-chart-block div canvas {
    margin: auto;
}
.search-icon-section {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: space-between;
}
.search-icon-section .custom-form-slect-field {
    width: 70%;
    margin-bottom: 0px !important;
    margin-right: 0px !important;
}
.search-icon-section .search-icon i.fa-solid.fa-magnifying-glass {
    font-size: 24px;
    color: #40a0c7;
}
.search-icon-section .custom-form-slect-field .dropdown #dropdown-basic {
    margin-right: 0px !important;
}
.search-input-holder {
    width: 93%;
}
.univeriy-cgp {
    display: flex;
   justify-content: space-between;
   width: 100%;
}
.Test-Sction {
    flex-direction: column;
    gap: 20px;
}
.playground-saved .Journey-responsive tbody {
    display: grid;
}
.playground-saved .Journey-responsive tbody tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e2e2e2;
}
.math-section #math {
    min-width: 110px;
    width: 100%;
}
.playground-saved .Journey-responsive tbody tr td {
    padding-top: 10px;
    padding-bottom: 10px;
}
.gets-started-form-holder .custom-form-slect-field .show.dropdown .dropdown-menu.show a.dropdown-item {
    height: 43px;
}
#view-modal .modal-body .Education-from .univeriy-cgp .form-group {
    width: 50%;
}
.landingpage-wrapper .pricing-block .course-slection-block .course-section {
    font-size: 21px;
}
.foot-submit-holder form .invalid-feedback {
    margin-top: 0px !important;
}
.line-chart-rightside .tabs-custom-listing-holder .circle-icon-holder h4 {
    font-weight: 500 !important;
}
.circle-icon-child-holder .circle-child-blue-icon{
    margin-left: 20px;
    font-size: 20px;
    cursor: pointer;
}
.circle-icon-child-holder .circle-child-blue-icon svg{
    color: #4FB5EF;
}
.circle-icon-holder.circle-icon-child-holder {
    margin-top: 4px;
}
.footer-top .list-holder a:hover {
    color: #0192A6;
}
.resume-modal {
    padding: 0px !important;
}

.leadership-block::-webkit-scrollbar {
    width: 8px;
}
.leadership-block::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
}
.leadership-block::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
}
.search-icon-section{width: 93%;}
.setting-block .setting-listing-holder.scrolled table.table .tabs-custom-listing-holder {
    padding-bottom: 20px;
    margin-bottom: 10px;
    padding-top: 10px;
}

.custom-modal1::-webkit-scrollbar {
    width: 8px;
}
.custom-modal1::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
}
.custom-modal1::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
}
#pricing #controlled-tab-example1 li #controlled-tab-example1-tab-annually {
    border-radius: 4px 0px 0px 4px;
}
#pricing #controlled-tab-example1 li #controlled-tab-example1-tab-one-time {
  border-radius: 0px 4px 4px 0px;
}
#pricing #controlled-tab-example1 li .senior-onetime {
    border-radius: 4px !important;
}
#view-modal .modal-tab-block form .form-group .education_value {
    line-height: 50px !important;
    height: 50px !important;
}
@-moz-document url-prefix() {
    .scrollable-container {
        scrollbar-width: thin;               
        scrollbar-color: #888 #f1f1f1;   
    }
    .leadership-block {
        scrollbar-width: thin;               
        scrollbar-color: #888 #f1f1f1;   
    }
    .autocomplete-search-container ul {
        scrollbar-width: thin;               
        scrollbar-color: #888 #f1f1f1;   
    }
    .card-view .card-body .card-content {
        scrollbar-width: thin;               
        scrollbar-color: #888 #f1f1f1;         
    }
    .dashboard-tab-section .dashboard-listings {
        scrollbar-width: thin;               
        scrollbar-color: #888 #f1f1f1;
    }
    .main-app-wrapper{
        scrollbar-width: thin;               
        scrollbar-color: #888 #f1f1f1;
    }
    .dashboard-listings-profile {
        scrollbar-width: thin;               
        scrollbar-color: #888 #f1f1f1;
    }
    .landingpage-wrapper {
        scrollbar-width: thin;               
        scrollbar-color: #888 #f1f1f1; 
    }
    .custom-modal1 {
        scrollbar-width: thin;               
        scrollbar-color: #888 #f1f1f1; 
    }
    .dashboard-dropdowns.show.dropdown .dropdown-menu.show {
        scrollbar-width: thin;               
        scrollbar-color: #888 #f1f1f1; 
      }
      .dashboard-dropdown-menu {
        scrollbar-width: thin;               
        scrollbar-color: #888 #f1f1f1; 
      }
      .form-group-activites-list-block {
        scrollbar-width: thin;               
        scrollbar-color: #888 #f1f1f1; 
      }
      textarea.form-control {
        scrollbar-width: thin;               
        scrollbar-color: #888 #f1f1f1; 
      }
      .main-app-wrappers {
        scrollbar-width: thin;               
        scrollbar-color: #888 #f1f1f1; 
      }
      #sportmanship-modal .sportmanship-from .custom-form-slect-field .dropdown-menu.show {
        scrollbar-width: thin;               
        scrollbar-color: #888 #f1f1f1;
      }
      #profile-dropdown .notication-title {
        scrollbar-width: thin;               
        scrollbar-color: #888 #f1f1f1;
      }
  }
  #view-modal .Education-from .univeriy-cgp .form-group .form-control:focus {
    box-shadow: 0px 1px 4.01px 0px #00000040 !important;
  }
  .main-app-wrapper .MainCustomRow .line-chart-leftside .Bar_chart.custom-line-chart-block .google-bar {
    padding: 10px 0px;
}
.landingpage-wrapper .foot-submit-holder form {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
}
.landingpage-wrapper .foot-submit-holder form button {
    max-width: 150px;
}
.settings-responsive::-webkit-scrollbar {
    width: 8px;
  }
.settings-responsive::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;

}
.settings-responsive::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
}
.admin-bar-chart-holder{padding-bottom: 10px;}

  .youtube-vid-contain{margin-top: 80px;}
  .youtube-vid-contain .ratio{
    width: 65%;
    margin: 0 auto;
  }
.one_tab_pricing .modal-header{right: 16px;}
.one_tab_pricing #pricing-modal .modal-content .modal-header i.fa-solid.fa-xmark{right: -16px;}
#view-modal .modal-content .Education-from .general-section .custom-form-slect-field #dropdown-basic::after {
    margin-top: 0px !important;
}
/*Tags Input Styling*/
.tags-input-resume-holder{width: 70%;}
.tags-input-resume-holder > div{
    border-radius: 8px;
    box-shadow: 0px 1.0029325485229492px 4.011730194091797px 0px #00000040;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000;
    text-align: center;
    margin-left: 15px;
    margin-right:15px;
    padding-top: 15px;
    padding-bottom: 15px;
    outline: none;
    border: none;
}
.tags-input-resume-holder > div > .rti--input::placeholder{
    opacity: 1;
    color:#000;
}
.tags-input-resume-holder > div .rti--input{
    width: auto;
    max-width: 82px;
    color:#000;
}
.tags-input-resume-holder > div .rti--input::placeholder{
    color:#c0c0c0;
    opacity: 1;
}
.tags-input-resume-holder .rti--tag button {
    padding-right: 0px;
}
.tags-input-resume-holder .rti--container:focus-within{
    border:none;
    box-shadow: 0px 1.0029325485229492px 4.011730194091797px 0px #00000040;
    outline: none;
}
.tags-input-resume-holder .rti--tag{
    background: #0092A6;
    color: #fff;
    padding: 3px 6px;
    white-space: normal;
    word-break: break-word;
}
.tags-input-resume-holder .rti--tag button {
    color:#fff;
    font-size: 14px;
    }
.tags-input-resume-holder .rti--tag button:hover{color:#fff;}
#newfrommodal .modal-content .modal-header {
    justify-content: space-between;
    padding: 0 0 20px !important;
    border: none;
}
#newfrommodal .modal-content .modal-header i.fa-solid.fa-xmark {
    cursor: pointer;
    padding: 5px 7px;
    border-radius: 100%;
}
#newfrommodal .modal-content .modal-header i.fa-solid.fa-xmark:hover {
    background-color: #038294;
    color: #ffffff;
}
#newfrommodal .modal-content .modal-header #contained-modal-title-vcenter h4 {
    margin-bottom: 0px;
}
#newfrommodal .modal-content #formLink {
    box-shadow: 0 1px 4.01px 0 rgba(0, 0, 0, .25098039215686274);
    height: 50px;
    border-radius: 8px;
    background: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    font-family: "Inter";
    padding: 0 15px;
}
#newfrommodal .modal-content .btn.btn-primary {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    width: 20%;
    background: #45aad6;
    border: none;
    font-weight: 700;
    line-height: 20px;
    padding: 10px 15px;
    font-family: "Inter";
    font-size: 17px;
}
#newfrommodal .modal-content #formLink:focus {
    border: 1px solid #fff !important;
    outline: none !important;
}
#newfrommodal .modal-content #formLink:focus-visible {
    outline: none !important;
}
#newfrommodal .modal-content #formTitle {
    box-shadow: 0 1px 4.01px 0 rgba(0, 0, 0, .25098039215686274);
    height: 50px;
    border-radius: 8px;
    background: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    font-family: "Inter";
    padding: 0 15px;
}
#newfrommodal .modal-content #formTitle:focus {
    border: 1px solid #fff !important;
    outline: none !important;
}
#newfrommodal .modal-content #formTitle:focus-visible {
    outline: none !important;
}
#newfrommodal .modal-content .link-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    gap: 15px;
}
#newfrommodal .modal-content .modal-body {
    padding: 0px;
}
#newfrommodal .modal-content {
    padding: 37px 37px 40px 37px;
}
#newfrommodal .modal-content .btn.btn-primary:active {
    color: #fff !important;
}
#custommodal2 .modal-content #controlled-tab-example-tabpane-general .custom-form-slect-field #dropdown-basic {
    margin: 0px;
}
.Test-button div .active {
    color: #0092A6;
}
#newfrommodal .modal-content .link-section div ol li::before {
    width: 2em !important;
    height: 2em !important;
    line-height: 2em !important;
  }
  #newfrommodal .modal-content .link-section div ol {
    padding-left: 10px !important;
  }
  .Description-section .text-description {
    box-shadow: 0 1px 4.01px 0 rgba(0, 0, 0, .25098039215686274);
    border-radius: 8px;
    background: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    font-family: "Inter";
    padding: 15px;
  }
  .Description-section .text-description:focus-visible {
    border: 1px solid #fff !important;
    outline: none !important;
  }
.feauture-section .feature-card {
    border-radius: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    background: rgba(255, 255, 255, 1);
    padding: 20px;
    height: 96px;
}
.feauture-section .feature-heading h5 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    margin-bottom: 0px;
    color: rgba(70, 78, 95, 1);
    white-space: nowrap;
}
/* Animation for fading in with delay */
@keyframes fadeInDelayed {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fade-in-delayed {
    opacity: 0;
    animation: fadeInDelayed 0.5s ease-in-out forwards; /* Animation forwards to maintain final state */
  }
  
  .fade-in-delayed:nth-child(1) {
    animation-delay: 0.1s;
  }
  
  .fade-in-delayed:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .fade-in-delayed:nth-child(3) {
    animation-delay: 0.3s;
  }
  
  .fade-in-delayed:nth-child(4) {
    animation-delay: 0.4s;
  }
  
  /* Add more nth-child selectors if you have more items */
  .main-app-wrapper.dashbaord-page::-webkit-scrollbar {
    width: 8px;
  }
  .main-app-wrapper.dashbaord-page::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 8px;
  }
  .main-app-wrapper.dashbaord-page::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 8px;
  }
  .gets-started-form-holder .account-anchor.active {
    max-width: fit-content;
}
.on-boarding-screens .leadership-block .autocomplete-search-container .input-group .form-control:focus{
    outline: none !important;
    box-shadow: none !important;
    border-color: #fff !important;
}
.autocomplete-search-container ul::-webkit-scrollbar {
    width: 8px;
  }
  .autocomplete-search-container ul::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }
  .autocomplete-search-container ul::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
  }
  /* dashboardtabs component */
  .dashboard-tab-section {
    background: rgba(255, 255, 255, 1);
    border-radius: 15px;
    padding: 30px;
}
.dashboard-tab-section ul li.nav-item a {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: rgba(70, 78, 95, 1);
}
/* dashboard-tab */
.dashboard-buttons {
    padding: 0px 15px 0px 0px;
}
.dashboard-tab-section .dashboard-listings {
    overflow-y: auto;
    height: calc(100dvh - 364px);
    display: block;
    overflow-x: hidden;
    padding-right: 5px;
}
.dashboard-tab-section .dashboard-listings::-webkit-scrollbar {
    width: 8px;
}
.dashboard-tab-section .dashboard-listings::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
} 
.dashboard-tab-section .dashboard-listings::-webkit-scrollbar-thumb {
    background: #888; 
      border-radius: 10px;
}
.dashboard-tab-section  {
 background: rgba(236, 242, 255, 1);
}
.dashboard-tab-section .content-dashboardtab h3 {
    font-family: Poppins;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #464e5f;
    font-size: 14px;
    margin-bottom: 0px;
    word-break: auto-phrase;
}

.content-dashboardtab {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.custom-pl-10 {
    padding-left: 10px;
  }
.dashboard-tab-section .dashboard-name-section .form-control {
    height: 47px;
    padding: 14px 20px 14px 20px;
    border-radius: 8px;
}
.dashboard-tab-section .dashboard-name-section .form-control:focus {
    outline: none !important;
    border-color: #ccc !important;
    box-shadow: none !important;
}
    

.dashboard-name-section ul li a {
    border: none !important;
}
.dashboard-name-section ul li .active {
    color: rgba(1, 146, 166, 1) !important;

}
.dashboard-name-section ul li .active::before {
    content: '';
    position: absolute;
    top: 25px;
    bottom: 0; 
    left: 0;
    width: 100%;
    height: 2px; 
    background-color: rgba(1, 146, 166, 1);
}
.dashboard-name-section ul li.nav-item {
    position: relative;
}
.card-content::-webkit-scrollbar {
    width: 8px;
}
.card-content::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
} 
.card-content::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
}
.dashboard-dropdowns.dropdown #dropdown-basic {
    height: 47px;
    padding: 15px 20px;
    border-radius: 8px;
    background-color: transparent;
    color: #949494;
    margin: 0px;
    border: 1px solid #dee2e6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 150px;
}
.dashboard-dropdowns.show.dropdown .dropdown-menu.show {
    min-width: 150px;
    padding: 0px;
    background-color: #fff;
    box-shadow: 0 1px 4.01px 0 rgba(0, 0, 0, .25098039215686274);
    border: none !important;
    height: 188px;
    overflow-y: auto;
    overflow-x: hidden;
}
.dashboard-dropdowns.show.dropdown .dropdown-menu.show a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 47px;
    line-height: 28px;
    border-bottom: 1px solid #ccc;
    padding: 15px 20px !important;
    font-size: 16px;
}
.Reading-section .btn.btn-primary:focus-visible {
    box-shadow: none !important;
}
.Majors-section .btn.btn-primary:focus-visible {
    box-shadow: none !important;
}
.custm-selct-icon-btn:focus-visible {
    box-shadow: none !important;
}
.dashboard-dropdowns.dropdown #dropdown-basic:focus-visible {
    box-shadow: none !important;
}
#newfrommodal .modal-content .btn.btn-primary:focus-visible {
    box-shadow: none !important;
}
.custom-modal2 .modal-footer .btn.btn-primary:focus-visible {
    box-shadow: none !important;
}
button.btn.btn-light:focus-visible {
    box-shadow: none !important;
}
.dashboard-dropdowns.show.dropdown .dropdown-menu.show a:focus-visible {
    outline: none !important;
}
.tags-input-resume-holder .rti--tag button:focus-visible{
    outline: none !important;
}
#view-modal #controlled-tab-example li button:focus-visible {
    box-shadow: none !important;
}
.scholarship-detail .col-10 a:focus-visible {
    outline: none !important;
}
#view-modal .modal-content .Education-from .general-section .custom-form-slect-field #dropdown-basic:focus-visible {
    box-shadow: none !important;
}
.custom-form-slect-field .dropdown-item:focus-visible {
    outline: none !important;
}
.scrollable-container button:focus-visible {
    box-shadow: none !important;
    background-color:#0092A6 !important;
}
.dashboard-buttons a:focus-visible {
    outline: none !important;
}
.dashboard-dropdowns.show.dropdown .dropdown-menu.show a:hover {
    background: #0092a6;
    color: #fff !important;
}
.dashboard-dropdowns.dropdown #dropdown-basic::after{
    display: none !important;
}
.dashboard-dropdowns.show.dropdown .dropdown-menu.show a:last-child {
    border-bottom-left-radius: 4px;
}
.dashboard-dropdowns.show.dropdown .dropdown-menu.show a:first-child {
    border-top-left-radius: 4px;
}
.custm-selct-icon-btn:active {
    color: #fff !important;
}
.no-record-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.no-record-section p {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 0px;
}
.no-record-section-story {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 220px);
}

.no-record-section-story p {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 0px;
}
.dashboard-tab-section .dashboard-listings .as-link svg {
    color: #0092A6;
    width: 20px;
    height: auto;
}
.story-content .container {
    padding: 0px;
}
.story-content .calendar {
    width: 100%;
}
.story-content .card-view {
    width: 100%;
}
.story-content .dashboard-image {
    width: 100%;
}
.calander-deshboards .container {
    padding: 0px;
}
.calander-deshboards .container .as-link .feature-card {
    width: 100%;
}
.calander-deshboards .calendar {
    width: 100%;
}
.calander-deshboards .card-group-section .card-view {
    width: 100%;
}
.card-link-section {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(93, 100, 115, 1);
    user-select: none;
}
.card-link-section:hover {
  
    color: rgba(93, 100, 115, 1) !important;
   
}

/* =====scholarship modal===== */
#scholarshipmodal {
    max-width: 900px;
}
#scholarshipmodal .modal-content .modal-header {
    border: none !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px 20px 0px;
}
#scholarshipmodal .modal-content {
    padding: 37px 37px 40px 37px;
    box-shadow: 0px 4px 4px 0px #00000040;
}
#scholarshipmodal .modal-content .modal-header h4 {
    margin-bottom: 0px;
    font-family: Poppins;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}
#scholarshipmodal .modal-content .modal-header i.fa-solid.fa-xmark {
    padding: 5px 7px;
    font-size: 20px;
    border-radius: 100%;
    cursor: pointer;
}
#scholarshipmodal .modal-content .modal-header i.fa-solid.fa-xmark:hover {
    background-color: #0092a6;
    color: #ffffff;
}
#scholarshipmodal .modal-body {
    padding: 0px;
}
#scholarshipmodal .modal-body .scholarship-detail .col-2 h4 {
    font-family: Poppins;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    text-align: left;
    margin-bottom: 0px;
    color: #000000 !important;
}
#scholarshipmodal .modal-body .scholarship-detail .col-10 p {
    font-family: Poppins;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #464e5f;
    font-size: 14px;
    margin-bottom: 0px;
    word-break: break-word;
    white-space: normal;
    text-align: justify;
}
#scholarshipmodal .modal-body .scholarship-detail .col-2 {
    background: rgba(236, 242, 255, 1);
    padding: 12px;
    border-radius: 5px;
    height: 44px;
    margin-bottom: 10px;
}
#change_gender .modal-header {
    border: none !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px 20px 0px;
}
#change_gender .modal-header i.fa-solid.fa-xmark {
    padding: 5px 7px;
    font-size: 20px;
    border-radius: 100%;
    cursor: pointer;
} 
#change_gender .modal-header i.fa-solid.fa-xmark:hover {
    background-color: #0092a6;
    color: #ffffff;
}
#change_gender .modal-content {
    padding: 37px 37px 40px 37px;
}
#change_gender .modal-content .modal-body {
    padding: 0px;
}
#change_gender .modal-header h4 {
    margin-bottom: 0px;
}
#change_gender .modal-footer {
    padding: 25px 0px 0px 0px;
    gap: 10px;
}
.journey-answer div {
    font-family: Poppins;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    font-size: 14px;
    margin-bottom: 0px;
    word-break: break-all;
    color: #464e5f;
}
.scholarship-dropdown .dashboard-dropdowns.dropdown #dropdown-basic {
    min-width: 125px !important;
}
.scholarship-dropdown .dashboard-dropdowns.dropdown .dropdown-menu.show {
    min-width: 130px !important;
    height: 139px;
    max-width: 130px !important;
}
#navbarNav li.nav-item.dropdown {
    max-width: 100%;
}
.scholarship-detail .col-10 {
    margin-bottom: 10px;
}
/* ========== */
#profile-dropdown .dropdown-menu {
    position: absolute;
  right: -19px;
  padding: 15px 10px;
  background-color: #ffffff;
  box-shadow: 0px 1px 4.01px 0px #00000040;
  border: none !important;
  
  width: 300px;
  border-radius: 13px;
}
#profile-dropdown  .notication-title{
    height: 213px;
    overflow-y: auto;
    overflow-x: hidden;

}
#profile-dropdown .dropdown-menu .dropdown-item:focus{
    background-color:#0092A6 !important;
    color:white;
    border-radius: 6px;
}
#profile-dropdown .dropdown-menu  .dropdown-item:active{
    background-color:unset !important
}
/* #profile-dropdown .dropdown-menu .dropdown-item{
    border-radius: 6px;
} */

#profile-dropdown .dropdown-menu .dropdown-item{
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    padding: 10px 23px !important;
    line-height: 20px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: rgba(70, 78, 95, 1);
    white-space: break-spaces;
    word-break: break-word;
}
#profile-dropdown .dropdown-menu .dropdown-item:hover {
    background: #0092A6;
    color: #fff !important;
    border-radius: 6px;
}
.notification-title h3 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    color: rgba(70, 78, 95, 1);
}
#profile-dropdown  .notication-title::-webkit-scrollbar {
  width: 8px;
}
#profile-dropdown  .notication-title::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}
#profile-dropdown  .notication-title::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}
#profile-dropdown {
    position: relative;
}
.profile_icons svg {
    width: 20px;
    height: 20px;
    color: rgb(133, 136, 138);
    display: none;
}
#description::-webkit-scrollbar {
    width: 8px;
}
#description::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
}
#description::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
}
.content-dashboardtab p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #464e5f;
    margin-bottom: 0px;
}
.content-dashboardtab h4 {
    font-family: Poppins;
    font-size: 17.05px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    text-align: left;
    color: rgba(70, 78, 95, 1) !important;
    margin-bottom: 0px;
}
.course-section.active p {
    margin-bottom: 0px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 20.63px;
    text-align: start;
}
.course-slection-block .course-section.active .scholarship-dropdown .dashboard-dropdowns.dropdown #dropdown-basic {
    font-size: 14px;
}
.scholarship-dropdown .dashboard-dropdowns.dropdown #dropdown-basic {
    font-family: Poppins;
    font-size: 14px;
    padding: 10px 15px;
}
.profile-responsive {
    width: 100% !important;
    justify-content: space-between !important;
}
/* ============ */
#mobile-navbar .profile-dropdown-2 {
    width: 100%;
}
#mobile-navbar .profile-dropdown-2 ul {
    background-color: #000 !important;
    margin: 0px !important;
    visibility: visible;
    display: block;
    width: 100%;
    padding: 0px;
    list-style: none;
    cursor: pointer;
    border: none !important;
    border-radius: 8px;
    box-shadow: 0 1px 4.01px 0 rgba(0, 0, 0, .25098039215686274) !important;
}
#mobile-navbar .profile-dropdown-2 ul li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 41px;
    border-bottom: 1px solid #ccc;
    padding: 10px 23px !important;
    color: #fff;
    margin-bottom: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
}
#mobile-navbar .profile-dropdown-2 ul li:first-child {
    border-top-left-radius: 4px;
}
#mobile-navbar .profile-dropdown-2 ul li:last-child {
    border-bottom: 0px !important;
}
#mobile-navbar .profile-dropdown-2 ul li:last-child {
    border-bottom-left-radius: 4px;
}
#mobile-navbar .profile-dropdown-2 ul li:hover {
    background: rgba(255, 255, 255, 0.1) !important;
    color: #fff;
}
.course-section.onboarding-screen-section.active {
    align-items: center;
    justify-content: center;
}
#mobile-navbar .profile-dropdown-2 .close {
    max-height: 0;
    overflow: hidden;
    display: block;
}
#profile-dropdown .dropdown-menu .no-record-notication-title .dropdown-item:hover {
    background-color: transparent !important;
    color: rgba(70, 78, 95, 1) !important;
}
#profile-dropdown .no-record-notication-title .dropdown-item {
    text-align: center;
    font-weight: 600;
    color: rgba(70, 78, 95, 1) !important;
    border: 0px;
}
#view-modal .modal-footer .btn.btn-primary:active {
    background: #45AAD6 !important;
    color: #fff !important;
}
#view-modal .modal-footer .btn.btn-primary:focus-visible {
   box-shadow: none !important;
}
.admin_logout:hover {
    border-radius: 8px !important;
}
.hide_x_Scroll{
    overflow-x: hidden;
}
.pricing-info-block .course-slection-block .course-section {
    margin-bottom: 0px;
    margin-right: 0px !important;
}
.pricing-info-block #controlled-tab-example1 li button {
    padding: 15px;
}
#join-modal .modal-header i.fa-solid.fa-xmark {
    position: absolute;
    top: 10px;
    right: 9px;
}
#join-modal .modal-header i.fa-solid.fa-xmark:hover {
    background-color: #0092a6;
    color: #fff;
}
.trending-title {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    align-items: center;
    display: flex;
    margin-bottom: 10px;
    color: rgba(70, 78, 95, 1);
}
.main-buttons-dropdown {
    display: flex;
    gap: 10px;
}
.btn-toolbar .btn-group {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-toolbar button.btn.btn-light {
    border-radius: 0px !important;
}
#mobile-navbar .profile-dropdown-2 ul li {
    padding: 10px 50px !important;
}
.main-app-wrappers .container {
    max-width: 100%;
    padding: 0px;
}
.get-login-screens {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100dvh - 0px);
    gap: 100px;
    margin: 0px auto;
    width: 80%;
}
.get-login-screens .get-started-info-holder h1 {
    font-family: Inter !important;
    font-size: 70px !important;
    font-weight: 700 !important;
    line-height: 72px !important;
    text-align: left !important;
    white-space: nowrap;
}
.scholarship-detail .row {
    margin-left: 0px;
    margin-right: 0px;
}
.dashboard-listings-profile {
    overflow-y: auto;
    height: calc(100dvh - 364px);
    display: block;
    overflow-x: hidden;
}
.dashboard-listings-profile::-webkit-scrollbar {
    width: 8px;
}
.dashboard-listings-profile::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
} 
.dashboard-listings-profile::-webkit-scrollbar-thumb {
    background: #888; 
      border-radius: 10px;
}
.dashboard-listings-profile .content-dashboardtab h3 {
    white-space: normal;
    word-break: break-word;
}
#join-modal .modal-content {
    max-width: 850px;
    margin: 42px auto 0px;
}
.autocomplete-search-container ul {
    text-align: start;
}
.gets-started-form-holder .account-anchor.active:focus-visible {
   box-shadow: none !important;
   outline: none !important;
}
.gets-started-form-holder .form-holder form button.btn.btn-primary.gets-form-btn:focus-visible {
   box-shadow: none !important;
}
  .autocomplete-search-container ul li {
    padding: 15px 20px !important;
    font-family: Poppins !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 24px;
    text-align: left !important;
}
.main-app-wrappers::-webkit-scrollbar {
    width: 8px;
}
.main-app-wrappers::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
}
.main-app-wrappers::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
}
.date-optional {
    width: 170px;
} 
textarea::-webkit-scrollbar {
    width: 8px;
}
textarea::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px; 
}
textarea::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px; 
} 
.col-6.d-flex.justify-content-end.align-items-end.scholarship-dropdown {
    flex-direction: row;
    align-items: center !important;
    justify-content: space-between !important;
}
input[type="date"]::-webkit-calendar-picker-indicator {
cursor: pointer;
}
input[type="date"] {
    cursor: pointer;
  }
.pricing-info-block .course-slection-block .course-section {
    height: auto;
}
#sportmanship-modal .custom-form-slect-field .dropdown button {
    box-shadow: none !important;
}
.custom-modal1.question-modal-block form.custom-form-modal .form-group-contain-sbmt-btn-holder button:active {
    background-color: #45AAD6 !important;
}
.dashboard-tab-section .dashboard-name-section .form-control:focus-visible {
    outline: none !important;
}
.course-section:focus-visible {
    outline: none !important;
}
/* macbooks*/

input[type="date"] {
    /* display: flex;
    align-items: center;
    text-align: -webkit-center; */
}
.next-page-btn-holder .custm-back-btn {
    margin: 0px !important;
}
.next-page-btn-holder .custm-next-btn {
    margin: 0px !important;
}
.news-page-listing .dashboard-card #scroll-container-main .content-dashboardtab h3 {
    white-space: normal;
    word-break: break-word;
}
.dashboard-dropdowns.dropdown #dropdown-basic svg {
    font-size: 14px;
}
#custommodal2 .modal-content .modal-tab-block #controlled-tab-example-tabpane-general .form-group button#dropdown-basic:active {
    background-color: transparent !important;
}
#accordion .btn.btn-primary:focus-visible{
    box-shadow: none !important;
    background-color: #0092a6 !important;
}
#custommodal2 .custom-form-slect-field .dropdown-item:first-child, #custommodal2 .custom-form-slect-field .dropdown-item:first-child {
    border-top-right-radius: 4px !important;
    border-top-left-radius: 4px !important;
}
/* forget screen  */
.gets-started-form-contain .form-holder form {
    gap: 10px;
    display: flex;
    flex-direction: column;
}
.gets-started-form-contain .form-holder form .form-group.get-started-form label {
    font-family: "Inter";
    font-weight: 700 !important;
    font-size: 19px;
    line-height: 23px;
    color: #262626 !important;
    margin-bottom: 10px !important;
}
.gets-started-form-contain .form-holder form .form-group.get-started-form .form-control {
    max-width: 463px;
    height: 43px;
    background: #fff;
    border: none;
    outline: none;
    border-radius: 7px;
    box-shadow: 0 .96px 3.83px 0 rgba(0, 0, 0, .25098039215686274);
    color: #000;
    position: relative;
}
.gets-started-form-contain .form-holder form .btn.btn-primary.gets-form-btn {
    max-width: 200px;
}
.get-login-screens .form-holder form h2 {
    margin-bottom: 0 !important;
    color: #262626 !important;
}

#newsDiv {
    overflow-y: auto; /* Ensures scrolling is enabled */
    max-height: 140px;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS devices */
  }
